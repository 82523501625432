import React, { useState } from "react";
import { FaFolder, FaDatabase, FaPlug } from "react-icons/fa";

function DataStoreType({setSelectedTab, tabs, defaultTab}) {
  const [selected, setSelected] = useState(defaultTab);

  const handleSelection = (name) => {
    setSelectedTab(name);
    setSelected(name);
  }


  const data = tabs || [
    { id: 2, name: "Data", icon: <FaDatabase />, quantity: 0 },
    { id: 1, name: "Business Documents", icon: <FaFolder />, quantity: 6 },
    { id: 3, name: "Advanced Data", icon: <FaPlug />, quantity: 0 },
  ];

  return (
    <div className="flex w-full bg-white p-0 pb-0 gap-0 border-b">
      {data.map((item) => (
        <div
          key={item.id}
          onClick={() => handleSelection(item.name)}
          className={`min-w-[80px] max-w-[80px] h-[80px] p-3 border flex flex-col items-center justify-center gap-2 cursor-pointer ${
            selected === item.name ? "bg-gray-300" : "bg-gray-100"
          }`}
        >
          {/* Icon */}
          <div className="relative">
            {item.icon}
            {item.quantity > 0 && (
              <div className="absolute top-[-8px] right-[-8px] w-4 h-4 bg-red-600 text-white text-xs rounded-full flex items-center justify-center">
                {item.quantity}
              </div>
            )}
          </div>
          {/* Label */}
          <div className="text-center text-xs text-gray-700 font-medium">
            {item.name}
          </div>
        </div>
      ))}
    </div>
  );
}

export default DataStoreType;

import { useState } from "react";
import SearchBar from "./SearchBar";
import { FaChevronDown, FaUser } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { GoGear } from "react-icons/go";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

function Header() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  return (
    <header className="flex items-center justify-between py-3 px-4 bg-[#1D1F27] border-b border-[#cccccc66]">
      {/* Logo Section */}
      <div className="flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#ff7a59"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
            fill="none"
            stroke="#ff7a59"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
            fill="none"
            stroke="#ff7a59"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {user?.client?.clientLogo && (
          <img
            className="ml-2"
            style={{ objectFit: "contain", maxHeight: 37, height: 37 }}
            src={user?.client?.clientLogo}
            width="100"
            height="100%"
          />
        )}
      </div>

      {/* Search Section */}
      <div className="flex-1 mx-4 max-w-[500px]">
        <SearchBar />
      </div>

      {/* User Section */}
      <div className="relative flex items-center gap-4">
        {/* Welcome Text - Hidden on Mobile */}
        <span className="hidden sm:inline text-xs font-bold text-white">
          Welcome, {user?.username}
        </span>

        {/* User Icon and Dropdown */}
        <div
          className="flex items-center border border-white rounded-full px-2 py-1 cursor-pointer"
          onClick={toggleDropdown}
        >
          <div className="border border-white rounded-full p-1">
            <FaUser color="#FFF" />
          </div>
          <FaChevronDown color="#FFF" className="ml-2" />
        </div>

        {/* Dropdown Menu */}
        {isDropdownOpen && (
          <div className="absolute right-0 top-full mt-2 w-40 bg-white shadow-lg rounded-sm text-black z-10">
            <div className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100">
              <GoGear />
              <span className="ml-2">Settings</span>
            </div>
            <div
              className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
              onClick={() =>
                logout().then(() => {
                  // navigate("/login");
                })
              }
            >
              <MdLogout />
              <span className="ml-2">Logout</span>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;

import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { UserInfoPopover } from "./UserInfoPopover";

export const Header = ({
  extraButtons = <></>,
  title = <span>Dashboard</span>,
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  // get current route
  const currentRoute = window.location.pathname;

  return (
    <header className="header">
      <div
        style={{
          display: "flex",
          // flexDirection:'column',
          flexWrap: "wrap",
        }}
      >
        <div className="logo">
          <img
            src="/logo.svg"
            alt="Logo"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />
          {title}
        </div>
        <nav>
          <ul>
            <li
              className={currentRoute.startsWith("/experts") ? "active" : ""}
              onClick={() => {
                navigate("/experts");
              }}
            >
              Experts
            </li>
            <li
              className={
                currentRoute.startsWith("/newexplorer") ? "active" : ""
              }
              onClick={() => {
                navigate("/newexplorer");
              }}
            >
              Explorer
            </li>
            <li
              className={currentRoute.startsWith("/dashboard") ? "active" : ""}
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              Dashboard
            </li>
          </ul>
        </nav>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "flex-end",
          minWidth: 106,
        }}
      >
        {extraButtons}
        <div className="icon-header" style={{ marginRight: 0 }}>
          <div
            className="bell-icon"
            style={{
              height: 38,
              width: 38,
            }}
          >
            <img
              src="/bell-icon.svg"
              className="header-icon-size"
              alt="Bell-icon"
              style={{
                height: 20,
                width: 20,
              }}
            />
          </div>
          <UserInfoPopover />
        </div>
      </div>
    </header>
  );
};

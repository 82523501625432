import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div className="relative flex flex-1 flex-row items-center">
      <div className="absolute flex items-center inset-y-0 left-0 pl-3">
        <FaSearch className="text-gray-400" size={20} />
      </div>
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        className="flex-1 flex h-[38px] pl-12 pr-3 py-[9px] bg-black/10 rounded-[10px] border-2 border-white text-white text-center"
        placeholder="Search Workspace"
      />
    </div>
  );
};

export default SearchBar;

import React, { useState, useRef, useEffect } from "react";
import {
  X,
  ThumbsUp,
  ThumbsDown,
  Copy,
  CheckCheck,
  Delete,
  DeleteIcon,
} from "lucide-react";
import ReactMarkdown from "react-markdown";
import "./chatbot.css";
import TypingIndicator from "./TypingIndicator";
import {
  FaDownload,
  FaFile,
  FaFileAlt,
  FaFolder,
  FaImage,
} from "react-icons/fa";
import { fileExplorerApi } from "../services/fileExplorerApi";

const ChatbotComponent = ({
  messages,
  onSubmit,
  currentStep,
  selectedFiles,
  setSelectedFiles,
  onRevertMessage,
  showHeader = true,
  faqs,
}) => {
  const getFileIcon = (file) => {
    if (file.fileType === "folder")
      return <FaFolder className="text-yellow-500" />;
    if (file.fileName.match(/\.(jpg|jpeg|png|gif)$/i))
      return <FaImage className="text-blue-500" />;
    if (file.fileName.match(/\.(txt|md|doc|docx)$/i))
      return <FaFileAlt className="text-gray-500" />;
    return <FaFile className="text-gray-400" />;
  };
  const messagesEndRef = useRef(null);
  const [inputText, setInputText] = useState("");
  // base64 encoded file with data URI to get the file type
  const [inputFile, setInputFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleDelete = (message) => {
    console.log(message);
    if (onRevertMessage) {
      onRevertMessage(message.text);
    }
  };

  const handleDownload = async (file) => {
    try {
      const blob = await fileExplorerApi.downloadFile("userId", file.fileId);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      console.error(err);
    }
  };
  const [quickActions, setQuickActions] = useState(
    faqs || ["Hi, How is the weather today?", "Show it as a widget", "🤖 FAQs"]
  );

  useEffect(() => {
    setQuickActions(faqs ||  ["Hi, How is the weather today?", "Show it as a widget", "🤖 FAQs"]);
  }, [faqs]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages.length]); // Scroll whenever messages change

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(inputText, inputFile);
    if (inputText.trim()) {
      setInputText("");
      setInputFile(null);
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    // Optional: Add toast notification here
    // toast.success('Copied to clipboard!');
  };

  const handleLike = (messageIndex) => {};

  const handleDislike = (messageIndex) => {};

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        padding: "10px",
        zIndex: "1000",
        background: "white",
        zIndex: 99,
        flex: 1,
        display: "flex",
        overflowY: "auto",
      }}
    >
      <div className="chat-window-panel flex flex-1">
        {/* Header */}
        {showHeader && (
          <div className="chat-header">
            <div className="header-content">
              <div className="bot-avatar">
                <img width={40} height={40} src="/chatbot.svg" alt="chatbot" />
              </div>
              <div className="bot-info">
                <h3 className="bot-name">Kaman AI</h3>
                <div className="online-status">
                  <div className="status-dot"></div>
                  <span>Online</span>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Chat Area */}
        <div className="chat-messages flex flex-1">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`message-wrapper ${message.sender}-message`}
            >
              <div className="message-bubble">
                <ReactMarkdown className="message-text">
                  {message.text?.length > 10000
                    ? `${message.text?.substring(0, 1000)}...`
                    : message.text}
                </ReactMarkdown>
                {message.file && (
                  <img
                    src={message.file}
                    alt="Uploaded file"
                    className="uploaded-thumbnail"
                  />
                )}
                <div className="message-info">
                  <span className="timestamp">{message.timestamp}</span>
                  {message.sender === "user" && (
                    <CheckCheck
                      color={!message.read ? "green" : "grey"}
                      size={18}
                    />
                  )}
                </div>
                {message.sender === "bot" && (
                  <div className="message-actions">
                    <button
                      onClick={() => handleCopy(message.text)}
                      className="action-btn"
                      title="Copy message"
                    >
                      <Copy size={18} />
                    </button>
                    <button
                      onClick={() => handleLike(index)}
                      className={`action-btn ${
                        message.isLiked ? "active" : ""
                      }`}
                      title="Like"
                    >
                      <ThumbsUp size={18} />
                    </button>
                    <button
                      onClick={() => handleDislike(index)}
                      className={`action-btn ${
                        message.isDisliked ? "inactive" : ""
                      }`}
                      title="Dislike"
                    >
                      <ThumbsDown size={18} />
                    </button>
                  </div>
                )}
                {message.sender === "user" && (
                  <div className="message-actions">
                    <button
                      onClick={() => handleDelete(message)}
                      className="action-btn"
                      title="Revert chat to before this question"
                    >
                      <DeleteIcon
                        size={18}
                        className="text-gray-800"
                        title="Revert chat to before this question"
                      />
                    </button>
                  </div>
                )}
              </div>
              <div className="message-avatar">
                {message.sender === "bot" ? (
                  <div className="bot-avatar">
                    <img
                      width={15}
                      height={15}
                      src="/chatbotAvatar.svg"
                      alt="chatbot"
                    />
                  </div>
                ) : (
                  <img
                    src="/chatbotAvatar.svg"
                    className="header-icon-size"
                    alt="Bell-icon"
                  />
                )}
              </div>
            </div>
          ))}
          <TypingIndicator currentStep={currentStep} />
          {/* Invisible div for scroll reference */}
          <div ref={messagesEndRef} />
        </div>
        {/* Footer */}
        <div className="chat-footer flex flex-col">
          <div className="quick-actions overflow-y-auto">
            {quickActions.map((action, index) => (
              <button
                key={index}
                className="action-button"
                onClick={(e) => {
                  onSubmit(action);
                }}
              >
                {action}
              </button>
            ))}
          </div>
          <div>
            {inputFile && (
              <img
                src={inputFile}
                alt="Uploaded file"
                className="uploaded-thumbnail"
              />
            )}
          </div>
          <div className="mb-4">
            {(selectedFiles || []).map((file, index) => (
              <div
                key={index}
                className="selected-file flex-row flex flex-1 mb-1"
                style={{ alignItems: "center" }}
              >
                {getFileIcon(file)}
                <span className="ml-2">{file.fileName}</span>
                <button
                  type="button"
                  className="ml-2"
                  onClick={() => {
                    selectedFiles.splice(index, 1);
                    setSelectedFiles([...selectedFiles]);
                  }}
                >
                  <X size={18} />
                </button>
                <button
                  type="button"
                  className="ml-2"
                  onClick={() => {
                    handleDownload(file);
                  }}
                >
                  <FaDownload className="mr-2 text-gray-500" />
                </button>
              </div>
            ))}
          </div>
          <form onSubmit={handleSubmit} className="message-form">
            <textarea
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onInput={(e) => {
                e.target.style.height = "auto";
                e.target.style.height = `${e.target.scrollHeight}px`;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit(e);
                }
              }}
              placeholder="Type your message here..."
              className="message-input"
              rows={1}
              style={{ overflow: "hidden", resize: "none" }}
            />
            <button
              type="button"
              onClick={() => fileInputRef.current.click()}
              className="file-upload-icon"
            >
              <svg
                viewBox="0 0 280.067 280.067"
                className="icon"
                height={24}
                width={24}
              >
                <g xmlns="http://www.w3.org/2000/svg">
                  <path
                    style={{ fill: "#D07C40" }}
                    d="M149.823,257.142c-31.398,30.698-81.882,30.576-113.105-0.429   c-31.214-30.987-31.337-81.129-0.42-112.308l-0.026-0.018L149.841,31.615l14.203-14.098c23.522-23.356,61.65-23.356,85.172,0   s23.522,61.221,0,84.586l-125.19,123.02l-0.044-0.035c-15.428,14.771-40.018,14.666-55.262-0.394   c-15.244-15.069-15.34-39.361-0.394-54.588l-0.044-0.053l13.94-13.756l69.701-68.843l13.931,13.774l-83.632,82.599   c-7.701,7.596-7.701,19.926,0,27.53s20.188,7.604,27.88,0L235.02,87.987l-0.035-0.026l0.473-0.403   c15.682-15.568,15.682-40.823,0-56.39s-41.094-15.568-56.776,0l-0.42,0.473l-0.026-0.018l-14.194,14.089L50.466,158.485   c-23.522,23.356-23.522,61.221,0,84.577s61.659,23.356,85.163,0l99.375-98.675l14.194-14.089l14.194,14.089l-14.194,14.098   l-99.357,98.675C149.841,257.159,149.823,257.142,149.823,257.142z"
                  />
                </g>
              </svg>
            </button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={(e) => {
                console.log(e.target.files);
                const file = e.target.files[0];
                if (file) {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    setInputFile(reader.result);
                  };
                  reader.readAsDataURL(file);
                }
              }}
              style={{ display: "none" }}
            />
            <button type="submit" className="send-button">
              <svg
                viewBox="0 0 24 24"
                className="send-icon"
                style={{ transform: "rotate(0deg)" }}
              >
                <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
              </svg>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChatbotComponent;

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const OIDCCallback = () => {
  const navigate = useNavigate();
  const { handleLoginCallback } = useAuth();
  const [error, setError] = useState(null);
  const calling = useRef(false);
  const processCallback = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');
      const params = urlParams.entries().reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});


      if (code && state) {
        if(calling.current) {
          return;
        }
        calling.current = true;
        await handleLoginCallback(params);
        navigate('/newexplorer');
      } else {
        throw new Error('Missing code or state in OIDC callback');
      }
    } catch (error) {
      console.error('Error processing OIDC callback:', error);
      setError('Failed to complete login. Please try again.');
      setTimeout(() => navigate('/login'), 3000);
    }
  };

  useEffect(() => {
    
    processCallback();
  }, []);

  if (error) {
    return <div className="error">{error}</div>;
  }

  return <div>Processing login...</div>;
};

export default OIDCCallback;
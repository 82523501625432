import React, { useEffect, useState } from "react";
import { appsApi } from "../../services/appsApi";

const ExpertContext = React.createContext();

export const useExpert = () => {
  return React.useContext(ExpertContext);
};

export const ExpertProvider = ({ children }) => {
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [selectedExpert, setSelectedExpert] = useState(null);
  const [components, setComponents] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [messages, setMessages] = useState([]);
  const [ws, setWs] = useState(null);
  const [downloadableFile, setDownloadableFile] = useState(null);
  const [session, setSession] = useState([]);
  const [currentStep, setCurrentStep] = useState(null);

  useEffect(() => {
    appsApi.getApplications().then((data) => {
      setApplications(data);
      if (data.length > 0) setSelectedApplication(data[0]);
    });
  }, []);

  useEffect(() => {
    if (!selectedApplication) return;
    setSelectedExpert(selectedApplication.options[0]);
  }, [selectedApplication]);

  useEffect(() => {
    if (selectedExpert) setComponents(selectedExpert.components || []);
  }, [selectedExpert]);

  useEffect(() => {
    if (!selectedExpert) return;
    setMessages([]);
    // check if current browser url is not localhost, if not get domain and create url as wss://<domain>/agent/ws/chat?token=<access_token> else
    // create url as ws://localhost:3011/agent/ws/chat?token=<access_token>
    const url = window.location.href.includes("localhost")
      ? "ws://localhost:3011/agent/ws/chat?token="
      : "wss://" + window.location.hostname + "/agent/ws/chat?token=";
    const _ws = new WebSocket(
      url +
        localStorage.getItem("access_token") +
        (session ? "&sessionId=" + session.sessionId : "") +
        (selectedApplication
          ? "&applicationId=" + selectedApplication.id
          : "") +
        (selectedExpert ? "&expertId=" + selectedExpert.id : "")
    );
    setWs(_ws);
    let interval = null;
    _ws.onopen = () => {
      console.log("Connected");
      interval = setInterval(() => {
        _ws.send("ping");
      }, 30000);
    };
    _ws.onmessage = (e) => {
      console.log("Message", e.data);
      if (e.data === "pong") {
        return;
      }
      const data = JSON.parse(e.data);
      if (data.sessionHistory) {
        console.log("Session History", data.sessionHistory);
        setMessages(
          (data.sessionHistory.chats || []).map((msg) => JSON.parse(msg))
        );
        setComponents(
          (data.sessionHistory.components || []).map((c) => JSON.parse(c))
        );
      } else if (data.type === "content") {
        const { content, id } = data;
        setMessages((prev) => {
          if (prev.find((m) => m.id === id))
            return prev.map((m) =>
              m.id === id ? { ...m, text: m.text + content } : m
            );
          else {
            return [
              ...prev,
              {
                text: content,
                sender: "bot",
                timestamp: new Date().toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: false,
                }),
                id,
              },
            ];
          }
        });
      } else
        Object.entries(data).forEach(([key, res]) => {
          console.log("Response", res);

          if (res && res.response) {
            // setMessages((prev) => {
            //   return [
            //     ...prev,
            //     {
            //       text: res.response,
            //       sender: "bot",
            //       timestamp: new Date().toLocaleTimeString("en-US", {
            //         hour: "numeric",
            //         minute: "2-digit",
            //         hour12: false,
            //       }),
            //     },
            //   ];
            // });
          } else if (res && res.dynamicComponent) {
            setComponents((p) => [...p, res.dynamicComponent]);
          } else if (res && res.fileId) {
            console.log("Setting Downloadable File", res.fileId);
            setSelectedFiles((p) => [...(p || []), res.fileId]);
            setDownloadableFile(res.fileId);
          } else if (key === "final_result") {
            setCurrentStep(null);
          } else if (res && res.suggestions) {
            setMessages((p) => [
              ...p.slice(0, p.length - 1),
              {
                ...p[p.length - 1],
                suggestions: res.suggestions,
              },
            ]);
          } else setCurrentStep(key);
        });
    };
    _ws.onclose = () => {
      console.log("Disconnected");
    };
    return () => {
      _ws.close();
      if (interval) clearInterval(interval);
    };
  }, [session, selectedExpert]);

  const addToComponents = (component) => {
    setComponents((prev) => [...prev, component]);
  };

  const removeFromComponents = (component) => {
    setComponents((prev) => prev.filter((c) => c !== component));
  };

  const onSubmit = (e, f, llm = "gpt-4o") => {
    if (!e) return;
    if (!ws) {
      console.error("WebSocket not connected");
      return;
    }
    console.log("Submitted", e);
    setMessages((prev) => {
      return [
        ...prev,
        {
          text: e,
          sender: "user",
          timestamp: new Date().toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "2-digit",
            hour12: false,
          }),
        },
      ];
    });
    ws.send(
      JSON.stringify({
        query: e,
        file: f,
        selectedFiles: selectedFiles.map((f) => f.fileId),
        llm,
      })
    );
    setSelectedFiles([]);
  };

  const saveApplication = (formData) => {
    appsApi.saveApplication(formData).then((data) => {
      appsApi.getApplications().then((data) => {
        setApplications(data);
        if (data.length > 0) setSelectedApplication(data[0]);
      });
    });
  };

  return (
    <ExpertContext.Provider
      value={{
        applications,
        selectedApplication,
        setSelectedApplication,
        selectedExpert,
        setSelectedExpert,
        components,
        addToComponents,
        removeFromComponents,
        selectedFiles,
        setSelectedFiles,
        chatHistory,
        setChatHistory,
        messages,
        setMessages,
        ws,
        setWs,
        downloadableFile,
        setDownloadableFile,
        session,
        setSession,
        currentStep,
        setCurrentStep,
        onSubmit,
        saveApplication,
      }}
    >
      {children}
    </ExpertContext.Provider>
  );
};

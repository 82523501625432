import React, { useEffect, useState } from "react";
import { FaPlus, FaEllipsisV } from "react-icons/fa";

export const ChatHistoryComponent = ({ onClick }) => {
  const [sessions, setSessions] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);

  // fetch sessions using http://localhost:3011/agent/api/chat api, pass Authorization header with value Bearer, get token from local storage
  useEffect(() => {
    getSessions();
  }, []);

  const getSessions = () => {
    const url = window.location.href.includes("localhost")
      ? "http://localhost:3011/agent/api/chat"
      : "https://" + window.location.hostname + "/agent/api/chat";
    fetch(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    }).then((res) => {
      res.json().then((data) => {
        console.log(data);
        setSessions(data);
      });
    });
  };

  const handleContextMenu = (event, session) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 80,
      session,
    });
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleRename = (session) => {
    const newName = prompt("Enter new name:", session.name);
    if (newName) {
      const url = window.location.href.includes("localhost")
        ? `http://localhost:3011/agent/api/chat/${session.sessionId}`
        : `https://${window.location.hostname}/agent/api/chat/${session.sessionId}`;
      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        body: JSON.stringify({ session: { name: newName } }),
      }).then(() => {
        getSessions();
      });
    }
    handleClose();
  };

  const handleDelete = (session) => {
    if (window.confirm("Are you sure you want to delete this session?")) {
      const url = window.location.href.includes("localhost")
        ? `http://localhost:3011/agent/api/chat/${session.sessionId}`
        : `https://${window.location.hostname}/agent/api/chat/${session.sessionId}`;
      fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      }).then(() => {
        getSessions();
      });
    }
    handleClose();
  };

  // show sessions in a list on click of a session, call onClick function with session Object
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        padding: 10,
        zIndex: 1000,
        background: "white",
      }}
    >
      <div className="chat-window-panel" style={{ width: "100%", minWidth: 0 }}>
        <div className="chat-header">
          <div className="header-content">
            <div className="bot-avatar">
              <img width="40" height="40" src="/chatbot.svg" alt="chatbot" />
            </div>
            <div className="bot-info">
              <h3 className="bot-name">Chat History</h3>
            </div>
            <FaPlus className="text-white cursor-pointer hover:bg-opacity-30 hover:bg-black p-1 w-6 h-6 m-1" title="New Chat"
              onClick={() => onClick({})}
            />
          </div>
        </div>
        <ul className="space-y-2 bg-white rounded-lg shadow-lg p-4 overflow-auto" style={{maxHeight: 'calc(100vh - 200px)'}}>
          {sessions.map((session) => (
            <li
              key={session.sessionId}
              onClick={() => onClick(session)}
              onContextMenu={(event) => handleContextMenu(event, session)}
              className="px-4 py-3 rounded-md hover:bg-gray-100 transition-colors cursor-pointer text-gray-700 hover:text-gray-900 flex items-center justify-between"
            >
              <div className="flex-1">
                <span>{session.name}</span>
                <br />
                <small>{new Date(session.created_at).toLocaleString()}</small>
              </div>
              <FaEllipsisV className="text-gray-400 cursor-pointer" />
            </li>
          ))}
        </ul>
        {contextMenu && (
          <div
            style={{
              position: "absolute",
              top: contextMenu.mouseY,
              left: contextMenu.mouseX,
              backgroundColor: "white",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              zIndex: 1000,
            }}
          >
            <ul className="list-none p-2 m-0">
              <li
                className="cursor-pointer p-2 hover:bg-gray-100"
                onClick={() => handleRename(contextMenu.session)}
              >
                Rename
              </li>
              <li
                className="cursor-pointer p-2 hover:bg-gray-100"
                onClick={() => handleDelete(contextMenu.session)}
              >
                Delete
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

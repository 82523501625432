import axios from "axios";
import { redirect } from "react-router-dom";

const API_BASE_URL =
  process.env.REACT_APP_API_URL || "http://localhost:3001/api";
const API_AUTH_URL =
  process.env.REACT_APP_API_AUTH_URL || "http://localhost:3002/auth";

const coreapi = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const authapi = axios.create({
  baseURL: API_AUTH_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

coreapi.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

authapi.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export const initiateLogin = async (clientId) => {
  const response = await authapi.get("/login?clientId=" + clientId);
  return response.data;
};

export const fetchApplications = async () => {
  const response = await coreapi.get("/applications");
  return response.data;
};

export const handleCallback = async (params, session) => {
  const response = await authapi.post("/callback", { ...params, session });
  return response.data;
};

export const logout = async () => {
  const idToken = localStorage.getItem("id_token");
  const response = await authapi.post("/logout", { id_token: idToken });
  const {url} = response.data;
  if(url) {
    console.log(response.data);
    // redirect(url);
    await axios.get(url);
  }
  localStorage.removeItem("access_token");
  return response.data.url;
};

export const getClients = async () => {
  const response = await authapi.get("/client-list");
  return response.data;
};

export const getCurrentUser = async () => {
  const response = await authapi.get("/userinfo");
  return response.data;
};

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    throw new Error("No refresh token available");
  }
  const response = await authapi.post("/token", {
    grant_type: "refresh_token",
    refresh_token: refreshToken,
  });
  return response.data;
};

const handleApiError = (error) => {
  if (error.response) {
    console.error("API Error Response:", error.response.data);
    console.error("API Error Status:", error.response.status);
  } else if (error.request) {
    console.error("API Error Request:", error.request);
  } else {
    console.error("API Error Message:", error.message);
  }
  throw error;
};

const createApiService = (resourceName) => ({
  getAll: () => coreapi.get(`/${resourceName}`).catch(handleApiError),
  getById: (id) => coreapi.get(`/${resourceName}/${id}`).catch(handleApiError),
  create: (data) =>
    coreapi.post(`/${resourceName}`, data).catch(handleApiError),
  update: (id, data) =>
    coreapi.put(`/${resourceName}/${id}`, removeId(data)).catch(handleApiError),
  delete: (id) =>
    coreapi.delete(`/${resourceName}/${id}`).catch(handleApiError),
});

const createAuthApiService = (resourceName) => ({
  getAll: () => authapi.get(`/${resourceName}`).catch(handleApiError),
  getById: (id) => authapi.get(`/${resourceName}/${id}`).catch(handleApiError),
  create: (data) =>
    authapi.post(`/${resourceName}`, data).catch(handleApiError),
  update: (id, data) =>
    authapi.put(`/${resourceName}/${id}`, removeId(data)).catch(handleApiError),
  delete: (id) =>
    authapi.delete(`/${resourceName}/${id}`).catch(handleApiError),
});

export const entityApi = createApiService("entities");
export const eventApi = createApiService("events");
export const eventHandlerApi = createApiService("event-handlers");
export const pluginApi = createApiService("plugin-registry");
export const triggerApi = createApiService("trigger-registry");
export const userApi = createAuthApiService("users");
export const roleApi = createAuthApiService("roles");
export const clientApi = createAuthApiService("clients");

export const customApi = {
  saveFlowData: (data) =>
    data.id
      ? coreapi
          .put(`/flow-data/${data.id}`, removeId(data))
          .catch(handleApiError)
      : coreapi.post("/flow-data", removeId(data)).catch(handleApiError),
  getFlowData: (id) =>
    id
      ? coreapi.get(`/flow-data/${id}`).catch(handleApiError)
      : [{ flow_data: { nodes: [], edges: [] }, status: "draft" }],
  getFlowDataAll: () => coreapi.get(`/flow-data`).catch(handleApiError),
  triggerEvent: (id, data, options = {}) =>
    coreapi
      .post(`/events/${id}/trigger`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        maxBodyLength: Infinity,
      })
      .catch(handleApiError),
  getEntityData: (id, q) =>
    coreapi
      .get(`/entity-data/${id}${q ? `?q=${q}` : ""}`)
      .catch(handleApiError),
  registerPlugin: (pluginData) =>
    coreapi.post("/plugin-registry", pluginData).catch(handleApiError),
  chat: (
    prompt,
    selectedEntityId,
    messages = [
      {
        role: "system",
        content:
          "You are a chart maker who would try and use the tool specified to create a chart. You have expertise in SQL and chart.js.Very Very important: The db you are using is postgres, use double quotes to wrap all table names and column names the query field of the tool_calling",
      },
    ]
  ) =>
    coreapi
      .post("/chat", {
        prompt,
        messages,
        selectedEntityId,
      })
      .catch(handleApiError),
  query: (query) =>
    coreapi.post("/chat/query", { query }).catch(handleApiError),
};

const removeId = (data) => {
  const newData = { ...data };
  delete newData.id;
  delete newData.created_at;
  delete newData.updated_at;
  return newData;
};

export const healthCheck = () => coreapi.get("/health").catch(handleApiError);

export default { coreapi, authapi };

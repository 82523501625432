import React, { useEffect, useState } from "react";
import { useExpert } from "./ExpertProvider";
import { appsApi } from "../../services/appsApi";
import JsonView from "@uiw/react-json-view";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const isTableDisplayable = (data) => {
  // Check if it's an array with at least one item
  if (!Array.isArray(data) || data.length === 0) return false;
  
  // Get keys of first object
  const firstItemKeys = Object.keys(data[0]);
  if (firstItemKeys.length === 0) return false;

  // Check if all items have the same structure
  return data.every(item => {
    const currentKeys = Object.keys(item);
    return currentKeys.length === firstItemKeys.length &&
           firstItemKeys.every(key => currentKeys.includes(key));
  });
};

const TableView = ({ data }) => {
  const headers = Object.keys(data[0]);
  
  return (
    <div className="overflow-x-auto overflow-y-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {headers.map(header => (
              <th 
                key={header}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((row, idx) => (
            <tr key={idx}>
              {headers.map(header => (
                <td 
                  key={`${idx}-${header}`}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  {typeof row[header] === 'object' 
                    ? JSON.stringify(row[header])
                    : String(row[header])
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const DataSourceList = () => {
  const { selectedExpert } = useExpert();
  const [dataSources, setDataSources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAccordions, setOpenAccordions] = useState({});  // Changed to object

  useEffect(() => {
    // fetch datasource using /ds
    appsApi
      .getDataSources(selectedExpert.id)
      .then((data) => {
        console.log(data);
        setDataSources(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedExpert]);

  const toggleAccordion = (id) => {
    setOpenAccordions(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  return (
    <div className="p-4 space-y-4">
      <h1 className="text-xl font-bold mb-6 text-gray-800">{selectedExpert?.name}</h1>
      
      {loading ? (
        <div className="flex items-center justify-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="space-y-2">
          {dataSources.map((dataSource) => (
            <div 
              key={dataSource.id}
              className="border rounded-lg overflow-hidden bg-white shadow-sm"
            >
              <button
                onClick={() => toggleAccordion(dataSource.id)}
                className="w-full px-4 py-3 flex items-center justify-between bg-gray-50 hover:bg-gray-100 transition-colors"
              >
                <div className="flex flex-col items-start">
                  <h2 className="font-medium text-gray-800">{dataSource.name}</h2>
                  <p className="text-sm text-gray-600">{dataSource.description}</p>
                </div>
                {openAccordions[dataSource.id] ? (
                  <FaChevronUp className="text-gray-500" />
                ) : (
                  <FaChevronDown className="text-gray-500" />
                )}
              </button>
              
              {openAccordions[dataSource.id] && (
                <div className="p-4 border-t">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">Value:</h3>
                  <div className="bg-gray-50 rounded-lg p-3">
                    {dataSource.type === "text" ? (
                      <p className="text-sm text-gray-600 break-words">{dataSource.value}</p>
                    ) : (
                      <div className="overflow-auto max-h-96">
                        {isTableDisplayable(dataSource.value) ? (
                          <TableView data={dataSource.value} />
                        ) : (
                          <JsonView 
                            value={dataSource.value} 
                            collapsed 
                            style={{ 
                              background: 'transparent',
                              fontSize: '0.875rem'
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      
      {!loading && dataSources.length === 0 && (
        <div className="text-center py-8 text-gray-500">
          No data sources available
        </div>
      )}
    </div>
  );
};


import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_AGENT_URL || 'http://localhost:3011/agent/api';

export const fileExplorerApi = {
  // Get files in a directory
  getFiles: async (userId, parentFolder) => {
    const response = await axios.get(`${API_BASE_URL}/files`, {
      params: { userId, parentFolder },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    return response.data;
  },

  // Get a single file by ID
  getFileById: async (userId, fileId) => {
    const response = await axios.get(`${API_BASE_URL}/files/${fileId}`, {
      params: { userId },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    return response.data;
  },

  // Create a new file or folder
  createFile: async (fileData) => {
    const response = await axios.post(`${API_BASE_URL}/files`, fileData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    return response.data;
  },

  // Update file metadata
  updateFile: async (fileData) => {
    const response = await axios.put(`${API_BASE_URL}/files/${fileData.fileId}`, fileData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    return response.data;
  },

  // Delete a file or folder
  deleteFile: async (userId, fileId) => {
    await axios.delete(`${API_BASE_URL}/files/${fileId}`, {
      params: { userId },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
  },

  // Upload file content
  uploadFile: async (userId, parentFolder, file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', userId);
    formData.append('parentFolder', parentFolder);

    const response = await axios.post(`${API_BASE_URL}/files/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    return response.data;
  },

  // Download file
  downloadFile: async (userId, fileId) => {
    const response = await axios.get(`${API_BASE_URL}/files/${fileId}/download`, {
      params: { userId },
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    return response.data;
  },
  getDownloadFileUrl: (fileId) => {
    return `${API_BASE_URL}/files/${fileId}/download?token=${localStorage.getItem('access_token')}`;
  },

  // Move file to different folder
  moveFile: async (userId, fileId, newParentFolder) => {
    const response = await axios.patch(`${API_BASE_URL}/files/${fileId}/move`, {
      userId,
      newParentFolder
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    return response.data;
  },

  // Copy file
  copyFile: async (userId, fileId, newParentFolder) => {
    const response = await axios.post(`${API_BASE_URL}/files/${fileId}/copy`, {
      userId,
      newParentFolder
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    return response.data;
  }
};
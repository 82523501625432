// UserInfoPopover.jsx
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { FaPowerOff } from "react-icons/fa";
import Popover from "./Popover";

export const UserInfoPopover = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Failed to logout:", error);
    }
  };
  
  return <Popover 
    position="bottom-right"
    triggerStyle={{
        maxHeight:38,
    }}
    trigger={
      <div
        style={{
            display:'flex',
            gap:6,
        }}
         className="user-info-wrapper"
    >
      <img
        src={user?.avatar || "/defaultuser.png"}
        alt="User avatar"
        style={{
          width: "45px",
          height: "45px",
          borderRadius: "50%",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
        className="nav-popover-user-info"
      >
        <span
          style={{
            fontWeight: "500",
            color: "#333",
            margin: "0",
          }}
        >
          {user?.name?.trim().split(" ")[0] ||
            user?.username?.trim().split(" ")[0] ||
            "User"}
        </span>
        <span
          style={{
            fontSize: "0.875rem",
            color: "#666",
            margin: "0",
          }}
        >
          {user?.role || "User"}
        </span>
      </div>
    </div>
    }
    content={
        <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            padding: "16px",
            minWidth: "256px",
            background: "white",
            borderRadius: "8px",
            zIndex: 1000,
         
          }}
         
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={user?.avatar || "/defaultuser.png"}
              alt="User avatar"
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
            <div
              style={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
             
            >
              <h3
                style={{
                  fontWeight: "600",
                  fontSize: "1.125rem",
                  color: "#333",
                  margin: "0",
                }}
              >
                {user?.name || user?.username || "User"}
              </h3>
              <p
                style={{
                  color: "#666",
                  margin: "4px 0 0 0",
                  fontSize: "0.875rem",
                }}
              >
                {user?.role || "User"}
              </p>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              paddingTop: "16px",
              borderTop: "1px solid #e5e7eb",
            }}
          >
            <button
              style={{
                width: "100%",
                padding: "8px",
                backgroundColor: "#f3f4f6",
                border: "none",
                borderRadius: "6px",
                color: "#333",
                cursor: "pointer",
                transition: "background-color 0.2s",
                alignItems: "center",
                display: "flex",
                gap: 10,
                marginBottom:8
              }}
              onClick={() => {
                /* Add profile action here */
              }}
            >
              <img
                src="/settings.svg"
                className="header-icon-size"
                alt="Bell-icon"
              />
              <span
                style={{
                  textAlign: "center",
                  width: "70%",
                  fontWeight: "500",
                  fontSize: 14,
                }}
              >
                Settings
              </span>
            </button>
            <button
              style={{
                width: "100%",
                padding: "8px",
                backgroundColor: "#f3f4f6",
                border: "none",
                borderRadius: "6px",
                color: "#333",
                cursor: "pointer",
                transition: "background-color 0.2s",
                alignItems: "center",
                display: "flex",
                gap: 10,
              }}
              onClick={() => {
                handleLogout();
              }}
            >
              <FaPowerOff size={'22px'} />
              <span
                style={{
                  textAlign: "center",
                  width: "70%",
                  fontWeight: "500",
                  fontSize: 14,
                }}
              >
                Logout
              </span>
            </button>
          </div>
        </div>
      </>
    }
  /> 
 
};

UserInfoPopover.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
    avatar: PropTypes.string,
    role: PropTypes.string,
  }),
};

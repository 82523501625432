import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import {
  FaFolder,
  FaFile,
  FaFileAlt,
  FaImage,
  FaEllipsisV,
  FaFolderPlus,
  FaUpload,
  FaSearch,
  FaTrash,
  FaPencilAlt,
  FaCopy,
  FaDownload,
  FaSync,
  FaArrowLeft,
} from "react-icons/fa";
import { fileExplorerApi } from "../../services/fileExplorerApi";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import Portal from "../Common/Portal";

function FileExplorer({
  userId,
  onSelectionChange,
  downloadableFile,
  grid = 6,
}) {
  const [fileStructure, setFileStructure] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [currentPath, setCurrentPath] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [editingFile, setEditingFile] = useState(null);
  const [currentDirectory, setCurrentDirectory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [docViewerVisible, setDocViewerVisible] = useState(false);
  const [docViewerFile, setDocViewerFile] = useState(null);

  useEffect(() => {
    if (onSelectionChange) onSelectionChange(selectedItems);
  }, [selectedItems]);

  const fetchFiles = async () => {
    try {
      setIsLoading(true);
      const parentFolder = currentDirectory.length
        ? currentDirectory[currentDirectory.length - 1].fileId
        : "root";
      const files = await fileExplorerApi.getFiles(userId, parentFolder);
      console.log(files);
      setFileStructure(files);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("====================================");
    console.log(contextMenu);
    console.log("====================================");
  }, [contextMenu]);

  useEffect(() => {
    fetchFiles();
  }, [downloadableFile]);

  const back = () => {
    if (currentDirectory.length > 0) {
      setCurrentDirectory((prev) => prev.slice(0, -1));
      setCurrentPath((prev) => prev.slice(0, -1));
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [currentDirectory, userId]);

  const getFileIcon = (file) => {
    if (file.fileType === "folder")
      return <FaFolder className="text-yellow-500" />;
    if (file.fileName.match(/\.(jpg|jpeg|png|gif)$/i))
      return <FaImage className="text-blue-500" />;
    if (file.fileName.match(/\.(txt|md|doc|docx)$/i))
      return <FaFileAlt className="text-gray-500" />;
    return <FaFile className="text-gray-400" />;
  };

  const navigateToFolder = (folder) => {
    setCurrentDirectory((prev) => [...prev, folder]);
    setCurrentPath((prev) => [...prev, folder]);
  };

  const navigateToBreadcrumb = (index) => {
    setCurrentDirectory(currentPath.slice(0, index + 1));
    setCurrentPath(currentPath.slice(0, index + 1));
  };

  const navigateToRoot = () => {
    setCurrentDirectory([]);
    setCurrentPath([]);
  };

  const handleCreateFolder = async () => {
    try {
      const parentFolder = currentDirectory.length
        ? currentDirectory[currentDirectory.length - 1].fileId
        : "root";
      const newFolder = await fileExplorerApi.createFile({
        userId,
        fileName: "New Folder",
        fileType: "folder",
        parentFolder,
        lastModified: new Date(),
        contentPath: "",
        contentVector: [],
        fileIcon: "folder",
        fileExtension: "",
        sharedWith: [],
        lastModifiedBy: userId,
      });
      fetchFiles();
      // setFileStructure((prev) => [...prev, newFolder]);
      // setEditingFile(newFolder);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleUploadFile = () => {
    fileInputRef.current?.click();
  };

  const handleFileUploadLogic = async (files) => {
    if (files.length === 0) return;

    try {
      setIsLoading(true);
      const parentFolder = currentDirectory.length
        ? currentDirectory[currentDirectory.length - 1].fileId
        : "root";

      for (const file of files) {
        await fileExplorerApi.uploadFile(userId, parentFolder, file);
      }

      // Refresh the file list
      const updatedFiles = await fileExplorerApi.getFiles(userId, parentFolder);
      setFileStructure(updatedFiles);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onFileUpload = async (e) => {
    const files = Array.from(e.target.files);
    await handleFileUploadLogic(files);
    e.target.value = "";
  };

  const handleExternalDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const items = e.dataTransfer.items;
    const files = [];

    // Handle both files and directories
    for (const item of items) {
      if (item.kind === "file") {
        files.push(item.getAsFile());
      }
    }

    await handleFileUploadLogic(files);
  };

  const handleMoveItem = (itemId, destinationId) => {
    // ...logic to move item using mock API...
  };

  const handleDragStart = (e, item) => {
    e.stopPropagation();
    setDraggedItem(item);
    e.dataTransfer.setData("text/plain", item.fileId);
    e.currentTarget.classList.add("opacity-50");
  };

  const handleDragOver = (e, item) => {
    e.preventDefault();
    if (item.type === "folder") {
      e.currentTarget.classList.add("bg-blue-50");
    }
  };

  const handleDragLeave = (e, item) => {
    e.currentTarget.classList.remove("bg-blue-50");
  };

  const handleDrop = (e, targetItem) => {
    e.preventDefault();
    e.currentTarget.classList.remove("bg-blue-50");

    if (
      draggedItem &&
      targetItem.type === "folder" &&
      draggedItem.fileId !== targetItem.fileId
    ) {
      setFileStructure((prev) => {
        const newStructure = prev.filter(
          (item) => item.fileId !== draggedItem.fileId
        );
        const targetFolder = newStructure.find(
          (item) => item.fileId === targetItem.fileId
        );
        if (targetFolder) {
          targetFolder.children = [
            ...(targetFolder.children || []),
            draggedItem,
          ];
        }
        return newStructure;
      });
    }
    setDraggedItem(null);
  };

  const handleContextMenu = (event, item) => {
    event.preventDefault();
    event.stopPropagation();
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      item: item,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleRename = (file) => {
    setEditingFile(file);
    setContextMenu(null);
  };

  const handleDelete = async (file) => {
    try {
      await fileExplorerApi.deleteFile(userId, file.fileId);
      setFileStructure((prev) =>
        prev.filter((item) => item.fileId !== file.fileId)
      );
    } catch (err) {
      setError(err.message);
    }
    setContextMenu(null);
  };

  const handleDownload = async (file) => {
    try {
      const blob = await fileExplorerApi.downloadFile(userId, file.fileId);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      setError(err.message);
    }
    setContextMenu(null);
  };

  const handleCopy = async (file) => {
    try {
      const parentFolder = currentDirectory.length
        ? currentDirectory[currentDirectory.length - 1].fileId
        : "root";
      const copiedFile = await fileExplorerApi.copyFile(
        userId,
        file.fileId,
        parentFolder
      );
      // refresh the file list
      setFileStructure((prev) => [...prev, copiedFile]);
    } catch (err) {
      setError(err.message);
    }
    setContextMenu(null);
  };

  const isImageFile = (file) => {
    return file.fileName.match(/\.(jpg|jpeg|png|gif|bmp|webp)$/i);
  };

  const getSupportedMimeType = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    const mimeTypes = {
      pdf: "application/pdf",
      doc: "application/msword",
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      xls: "application/vnd.ms-excel",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ppt: "application/vnd.ms-powerpoint",
      pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      txt: "text/plain",
      csv: "text/csv",
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      png: "image/png",
      gif: "image/gif",
      bmp: "image/bmp",
      webp: "image/webp",
    };
    return mimeTypes[extension] || null;
  };

  const handleFileOpen = async (file) => {
    try {
      const mimeType = getSupportedMimeType(file.fileName);
      if (mimeType) {
        // const blob = await fileExplorerApi.downloadFile(userId, file.fileId);
        const url = fileExplorerApi.getDownloadFileUrl(file.fileId);
        setDocViewerFile({
          uri: url,
          fileType: mimeType,
          fileName: file.fileName,
        });
        setDocViewerVisible(true);
      } else {
        handleDownload(file);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const renderContextMenu = (item) => {
    const isFolder = item.type === "folder";
    return (
      <div className="absolute z-50 w-48 py-2 bg-white rounded-lg shadow-xl border border-gray-200">
        <div
          className="px-4 py-2 hover:bg-blue-50 cursor-pointer flex items-center text-xs"
          onClick={() => handleRename(item)}
        >
          <FaPencilAlt className="mr-2 text-gray-500" />
          Rename
        </div>
        <div
          className="px-4 py-2 hover:bg-blue-50 cursor-pointer flex items-center text-xs"
          onClick={() => handleCopy(item)}
        >
          <FaCopy className="mr-2 text-gray-500" />
          Copy
        </div>
        {!isFolder && (
          <div
            className="px-4 py-2 hover:bg-blue-50 cursor-pointer flex items-center text-xs"
            onClick={() => handleDownload(item)}
          >
            <FaDownload className="mr-2 text-gray-500" />
            Download
          </div>
        )}
        <div
          className="px-4 py-2 hover:bg-blue-50 cursor-pointer flex items-center text-xs text-red-500"
          onClick={() => handleDelete(item)}
        >
          <FaTrash className="mr-2" />
          Delete
        </div>
      </div>
    );
  };

  const renderBreadcrumb = () => (
    <div className="flex items-center space-x-2 mb-1 text-xs">
      <span
        className="cursor-pointer hover:text-blue-500"
        onClick={navigateToRoot}
      >
        Root
      </span>
      {currentPath.map((path, index) => (
        <React.Fragment key={path.fileId}>
          <span>/</span>
          <span
            className="cursor-pointer hover:text-blue-500"
            onClick={() => navigateToBreadcrumb(index)}
          >
            {path.fileName}
          </span>
        </React.Fragment>
      ))}
    </div>
  );

  // Create a portal component for the modal
  const DocumentViewerModal = () => {
    if (!docViewerVisible) return <></>;

    return ReactDOM.createPortal(
      <div className="fixed inset-0 bg-black bg-opacity-50 z-[9999] flex items-center justify-center p-4">
        <div className="bg-white rounded-lg w-full max-w-5xl h-[90vh] flex flex-col">
          <div className="flex justify-between items-center p-4 border-b">
            <h3 className="font-medium">{docViewerFile.fileName}</h3>
            <button
              onClick={() => {
                setDocViewerVisible(false);
                if (docViewerFile?.[0]?.uri) {
                  URL.revokeObjectURL(docViewerFile[0].uri);
                }
                setDocViewerFile(null);
              }}
              className="text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
          </div>
          <div className="flex-1 overflow-hidden">
            <DocViewer
              documents={docViewerFile ? [docViewerFile] : []}
              style={{ height: "100%", overflow: "auto" }}
              key={docViewerFile.fileName}
              config={{
                pdfVerticalScrollByDefault: true,
              }}
              pluginRenderers={DocViewerRenderers}
            />
          </div>
        </div>
      </div>,
      document.body
    );
  };

  // Modify the renderFiles function to use the new modal component
  const renderFiles = (files) => (
    <div
      className={`grid grid-cols-2 md:grid-cols-2 lg:grid-cols-${grid} gap-4 p-2 overflow-auto flex-1`}
    >
      {fileStructure.map((file) => (
        <div
          key={file.fileId}
          className={`
            group relative flex flex-col items-center p-3 rounded-lg
            hover:bg-gray-50 cursor-pointer transition-all duration-200
            ${
              selectedItems.map((f) => f.fileId).includes(file.fileId)
                ? "bg-blue-50"
                : ""
            }
            max-w-[120px]
          `}
          draggable
          onDragStart={(e) => handleDragStart(e, file)}
          onDragOver={(e) => handleDragOver(e, file)}
          onDragLeave={(e) => handleDragLeave(e, file)}
          onDrop={(e) => handleDrop(e, file)}
          onClick={(e) => {
            if (e.metaKey || e.ctrlKey) {
              setSelectedItems((prev) =>
                prev.includes(file.fileId)
                  ? prev.filter((fileId) => fileId !== file.fileId)
                  : [...prev, file]
              );
            } else {
              setSelectedItems([file]);
            }
          }}
          onDoubleClick={() => {
            if (file.fileType === "folder") {
              navigateToFolder(file);
            } else {
              handleFileOpen(file);
            }
          }}
          onContextMenu={(e) => handleContextMenu(e, file)}
        >
          <div className="text-4xl mb-2 relative">
            {getFileIcon(file)}
            <button
              className="absolute -right-2 -top-2 p-1 rounded-full bg-gray-100 
                         opacity-0 group-hover:opacity-100 transition-opacity"
              onClick={(e) => {
                e.stopPropagation();
                handleContextMenu(e, file);
              }}
            >
              <FaEllipsisV className="w-3 h-3 text-gray-500" />
            </button>
          </div>
          {editingFile?.fileId === file.fileId ? (
            <input
              type="text"
              className="w-full text-center border rounded px-2 py-1 text-sm"
              defaultValue={file.fileName}
              autoFocus
              onBlur={(e) => {
                setFileStructure((prev) =>
                  prev.map((item) =>
                    item.fileId === file.fileId
                      ? { ...item, name: e.target.value }
                      : item
                  )
                );
                // Update the file name in the database
                fileExplorerApi
                  .updateFile({
                    fileId: file.fileId,
                    fileName: e.target.value,
                  })
                  .then(() => {
                    fetchFiles();
                  });
                setEditingFile(null);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") e.target.blur();
                if (e.key === "Escape") setEditingFile(null);
              }}
            />
          ) : (
            <span className="text-xs text-center w-full break-words">
              {file.fileName}
            </span>
          )}
          {/* {contextMenu?.id === file.fileId && renderContextMenu(file)} */}
        </div>
      ))}

      {/* <Viewer
        visible={viewerVisible}
        onClose={() => {
          setViewerVisible(false);
          if (viewerFile?.url) URL.revokeObjectURL(viewerFile.url);
          setViewerFile(null);
        }}
        images={
          viewerFile ? [{ src: viewerFile.url, alt: viewerFile.fileName }] : []
        }
        zIndex={9999}
      /> */}

      <DocumentViewerModal />
    </div>
  );

  return (
    <div
      className="h-full flex flex-col bg-white flex-1 overflow-auto"
      onClick={() => {
        setContextMenu(null);
        if (editingFile) setEditingFile(null);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.classList.add("bg-blue-50");
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.classList.remove("bg-blue-50");
      }}
      onDrop={(e) => {
        e.currentTarget.classList.remove("bg-blue-50");
        handleExternalDrop(e);
      }}
    >
      <div
        className="border-b flex items-center p-2 bg-gray-50"
        style={{ flexWrap: "wrap", gap: 5 }}
      >
        <div className="flex space-x-2">
          <button
            className="flex items-center px-3 py-1.5 text-sm rounded-md
                       bg-gray-100 hover:bg-gray-200 text-gray-700 disabled:text-gray-300"
            onClick={back}
            disabled={currentDirectory.length === 0}
          >
            <FaArrowLeft className="mr-1.5" />
          </button>
          <button
            className="flex items-center px-3 py-1.5 text-sm rounded-md
                       bg-gray-100 hover:bg-gray-200 text-gray-700"
            onClick={handleCreateFolder}
          >
            <FaFolderPlus className="mr-1.5" />
            <span className="hide-on-xs hide-on-ms text-xs">New Folder</span>
          </button>
          <button
            className="flex items-center px-3 py-1.5 text-sm rounded-md
                       bg-gray-100 hover:bg-gray-200 text-gray-700"
            onClick={handleUploadFile}
          >
            <FaUpload className="mr-1.5" />
            <span className="hide-on-xs hide-on-ms text-xs">Upload</span>
          </button>
          <button
            className="flex items-center px-3 py-1.5 text-sm rounded-md
                       bg-gray-100 hover:bg-gray-200 text-gray-700"
            onClick={fetchFiles}
          >
            <FaSync className="mr-1.5" />
            <span className="hide-on-xs hide-on-ms text-xs">Refresh</span>
          </button>
        </div>
        <div className="ml-4 flex-1 max-w-md" style={{ minWidth: 150 }}>
          <div className="relative">
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search"
              className="w-full pl-9 pr-4 py-1.5 text-sm rounded-md border
                       border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
          </div>
        </div>
      </div>

      {/* Add this hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        onChange={onFileUpload}
        multiple
        accept="*"
      />
      <div className="p-3">
        {renderBreadcrumb()}

        <div className="flex-1 overflow-auto">{renderFiles(fileStructure)}</div>
      </div>

      {contextMenu && (
        <Portal>
          <div
            className="fixed bg-white rounded-lg shadow-xl border border-gray-200 py-2 z-50"
            style={{
              top: `${contextMenu.y}px`,
              ...(contextMenu.x > window.innerWidth / 2
                ? { left: `${contextMenu.x - 200}px` }
                : { left: `${contextMenu.x}px` }),
            }}
          >
            {renderContextMenu(contextMenu.item)}
          </div>
        </Portal>
      )}

      {error && (
        <div className="p-2 bg-red-100 text-red-600 text-sm">
          {error}
          <button className="ml-2 font-bold" onClick={() => setError(null)}>
            ✕
          </button>
        </div>
      )}

      {isLoading && (
        <div className="flex items-center justify-center p-4">Loading...</div>
      )}
    </div>
  );
}

export default FileExplorer;

import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { getClients } from "../services/api";

const Login = () => {
  const { login } = useAuth();
  const [selectedClient, setSelectedClient] = useState("");

  const [clients, setClients] = useState([]);

  useEffect(() => {
    getClients().then((data) => {
      setClients(data);
    });
  }, []);

  const handleLogin = async () => {
    try {
      if(!selectedClient) {
        return;
      }
      const loginUrl = await login(selectedClient);
      window.localStorage.setItem("state", loginUrl.session);
      window.location.href = loginUrl.url;
    } catch (error) {
      console.error("Failed to initiate login:", error);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h1 className="text-2xl font-bold text-center mb-6 text-gray-800">
          Login
        </h1>
        <div className="mb-6">
          <label
            htmlFor="client-select"
            className="block mb-2 text-sm font-medium text-gray-600"
          >
            Select Client
          </label>
          <select
            id="client-select"
            value={selectedClient}
            onChange={(e) => setSelectedClient(e.target.value)}
            required
            className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Choose a client...</option>
            {clients.map((client) => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={handleLogin}
          disabled={!selectedClient}
          className={`w-full py-3 px-4 rounded-md text-white text-sm font-medium transition-colors
            ${
              selectedClient
                ? "bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                : "bg-gray-400 cursor-not-allowed"
            }`}
        >
          Sign In
        </button>
      </div>
    </div>
  );
};

export default Login;

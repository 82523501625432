import React from "react";
import DataStoreType from "./DataStoreType";
import FileFolderDisplay from "./FileFolderDisplay";
import { FaDatabase, FaFolder, FaPlug, FaTimes } from "react-icons/fa";
import FileExplorer from "./FileExplorer";
import { useExpert } from "./ExpertProvider";
import { DataSourceList } from "./DataSourceList";

const DataSource = ({ isDataSourceOpen, setIsDataSourceOpen }) => {
  const { downloadableFiles, setSelectedFiles } = useExpert();
  const [selectedTab, setSelectedTab] = React.useState("Data");

  return (
    <div
      className={`max-w-full h-full flex flex-col bg-[#ebebeb] shadow-[0px_2px_4px_0px_rgba(0,0,0,0.25)] border-l border-[#9f9f9f] overflow-hidden transform transition-transform duration-500 ease-[cubic-bezier(0.25, 0.8, 0.25, 1)]  ${
        isDataSourceOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      {/* Header */}
      <div className="w-full h-[62px] pl-[27px] pr-3.5 py-[11px] bg-white shadow-[0px_2px_4px_0px_rgba(0,0,0,0.25)] border-b border-[#9f9f9f] flex justify-between items-center">
        <div className="text-black text-sm font-bold font-['Roboto']">
          Data Sources
        </div>
        <button
          onClick={() => setIsDataSourceOpen(false)}
          className="p-[5.5px] rounded-[5px] flex items-center justify-center"
        >
          <FaTimes size={15} />
        </button>
      </div>

      {/* DataStoreType */}
      <div className="w-full flex-shrink-0 bg-white">
        <DataStoreType
          setSelectedTab={setSelectedTab}
          defaultTab={"Data"}
          tabs={[
            { id: 2, name: "Data", icon: <FaDatabase />, quantity: 0 },
            {
              id: 1,
              name: "Business Documents",
              icon: <FaFolder />,
              // quantity: 6,
            },
            // { id: 3, name: "Advanced Data", icon: <FaPlug />, quantity: 0 },
          ]}
        />
      </div>

      {/* FileFolderDisplay */}
      <div className="w-full flex-grow bg-white overflow-auto">
        {selectedTab === "Business Documents" && (
          <FileExplorer
            grid={3}
            downloadableFile={downloadableFiles}
            onSelectionChange={setSelectedFiles}
          />
        )}
        {selectedTab === "Data" && <DataSourceList />}
        {selectedTab === "Advanced Data" && <div>Advanced data</div>}
      </div>
    </div>
  );
};

export default DataSource;

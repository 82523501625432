import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import OIDCCallback from "./components/OIDCCallback";
import "./App.css";
import Editor from "./components/Editor";
import DashboardPage from "./components/ChartsDashboard";
import MonitorFlowContent from "./components/Dashboard/Monitor-flow-content";
import Explorer from "./components/Explorer";
import Experts from "./components/experts";
import { ExpertProvider } from "./components/Experts/ExpertProvider";

const AppRoutes = () => {
  const { loading, error, user } = useAuth();

  if (loading) {
    return (
      <div className="container-center">
        <div className="loading">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container-center">
        <div className="error">{error}</div>
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path="/login"
        element={user ? <Navigate replace to="/experts" /> : <Login />}
      />
      <Route path="/callback" element={<OIDCCallback />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/flow-status"
        element={
          <ProtectedRoute>
            <MonitorFlowContent />
          </ProtectedRoute>
        }
      />
      <Route path="/" element={<Navigate replace to="/experts" />} />
      <Route
        path="/explorer"
        element={
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/newexplorer"
        element={
          <ProtectedRoute>
            <Explorer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/editor"
        element={
          <ProtectedRoute>
            <Editor />
          </ProtectedRoute>
        }
      />
      <Route
        path="/experts"
        element={
          <ProtectedRoute>
            <ExpertProvider>
              <Experts />
            </ExpertProvider>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <AppRoutes />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;

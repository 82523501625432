import React, { useCallback, useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp, FaTimes } from "react-icons/fa";
import LeftBar from "./LeftBar";
import { useExpert } from "./ExpertProvider";
import { ChatHistoryComponent } from "./ChatHistoryComponent";
import { useAuth } from "../../context/AuthContext";

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const [showChatHistory, setShowChatHistory] = useState(true);
  const [showNavigation, setShowNavigation] = useState(true);

  const {
    selectedExpert,
    setSelectedExpert,
    applications,
    selectedApplication,
    setSelectedApplication,
    saveApplication
  } = useExpert();
  const { user } = useAuth();
  // Content component to avoid duplication
  const SidebarContent = useCallback(
    () => (
      <div className="flex flex-row h-full">
        <LeftBar
          applications={applications}
          setSelectedApplication={setSelectedApplication}
          selectedApplication={selectedApplication}
          saveApplication={saveApplication}
        />
        <div className="w-full text-white flex flex-col max-h-[calc(100vh-62px)]">
          {/* Header Section */}
          <div className="flex flex-row h-auto">
            <div className="flex-1">
              <div className="flex h-[62px] items-center justify-between px-4 py-2 border-b border-gray-700">
                <div>
                  <div className="flex flex-row">
                    <h1 className="text-sm font-bold">
                      {selectedApplication?.name || "New Application"}
                    </h1>
                    <button
                      onClick={() => setShowNavigation((prev) => !prev)}
                      className="text-gray-500 hover:text-white focus:outline-none ml-2"
                    >
                      {showNavigation ? (
                        <FaChevronUp size={14} />
                      ) : (
                        <FaChevronDown size={14} />
                      )}
                    </button>
                  </div>
                  <span className="text-xs flex items-center space-x-1">
                    <span className="text-green-400 text-xl">&#9679;</span>
                    <span>{user?.username}</span>
                  </span>
                </div>
                <button
                  onClick={() => setIsSidebarOpen(false)}
                  className="top-2 right-2 text-white hover:text-gray-500 z-10"
                >
                  <FaTimes size={15} />
                </button>
              </div>
            </div>
          </div>

          {/* Navigation Section */}
          {showNavigation && (
            <nav className="flex min-w-full max-h-[calc(50vh-62px)] overflow-y-auto">
              <ul className="space-y-2 p-4 pl-0 pr-1 w-full">
                {selectedApplication?.options.map((item, index) => (
                  <li key={index}>
                    <button
                      className={`w-full font-bold  text-left py-4 px-4 my-0 rounded-r-full  ${
                        selectedExpert === item
                          ? "border-lime-600 border-l-4"
                          : ""
                      } ${
                        selectedExpert === item ? "bg-[#000000A0]" : "bg-[#111]"
                      } hover:bg-gray-700`}
                      onClick={() => {
                        setSelectedExpert(item);
                      }}
                    >
                      {item.name}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          )}

          {/* Chat History Section */}
          <div className="border-t border-gray-700 flex flex-col flex-1 overflow-y-auto">
            <div className="flex justify-between items-center p-4">
              <h1 className="text-sm font-bold">Chat History</h1>
              <button
                onClick={() => setShowChatHistory((prev) => !prev)}
                className="text-gray-500 hover:text-white focus:outline-none"
              >
                {showChatHistory ? (
                  <FaChevronUp size={14} />
                ) : (
                  <FaChevronDown size={14} />
                )}
              </button>
            </div>
            {showChatHistory && (
              <div className="p-2 space-y-2 overflow-y-auto flex-1 flex">
                <ChatHistoryComponent
                  key="chatHistory"
                  onClick={(session) => {
                    console.log({ session });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    ),
    [applications, selectedApplication, selectedExpert, user]
  );

  return (
    <>
      {/* Desktop Sidebar ${
          applications?.length > 1 || (user?.roles || []).includes("admin")
            ? "w-80"
            : "w-60"
        } */}
      <div
        className={`hidden md:block  bg-[#1B1A21] h-full transform transition-transform duration-500 ease-[cubic-bezier(0.25, 0.8, 0.25, 1)] ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <SidebarContent />
      </div>

      {/* Mobile Sidebar */}
      <div
        className={`fixed inset-0 z-50 md:hidden transition-opacity duration-500 ease-[cubic-bezier(0.25, 0.8, 0.25, 1)] ${
          isSidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        {/* Backdrop */}
        <div
          className="absolute inset-0 bg-black bg-opacity-50"
          onClick={() => setIsSidebarOpen(false)}
        />

        {/* Drawer */}
        <div
          className={`absolute left-0 top-0 w-72 h-full bg-gray-900 transform transition-transform duration-500 ease-[cubic-bezier(0.25, 0.8, 0.25, 1)] ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <SidebarContent />
        </div>
      </div>
    </>
  );
};

export default Sidebar;

import React, { useEffect, useState } from "react";
import Header from "./Experts/Header";
import DataSource from "./Experts/DataSource";
import Sidebar from "./Experts/SideBar";
import { Playground } from "./Experts/Playground";
import { FaBars } from "react-icons/fa";
import { useAuth } from "../context/AuthContext";
import { useExpert } from "./Experts/ExpertProvider";
import ChatbotComponent from "./Experts/ChatBotComponent";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

const ForCasting = () => {
  const { user } = useAuth();
  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth);
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false);
        setIsDataSourceOpen(false);
      } else {
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isDataSourceOpen, setIsDataSourceOpen] = useState(false);
  const {
    applications,
    chatHistory,
    selectedApplication,
    setSelectedApplication,
    selectedExpert,
    selectedFiles,
    setSelectedFiles,
    messages,
    setMessages,
    onSubmit,
    ws,
    setWs,
    downloadableFile,
    setDownloadableFile,
    session,
    currentStep,
    setCurrentStep,
    components,
  } = useExpert();

  const renderResizeHandle = () => (
    <PanelResizeHandle className=" bg-gray-700 hover:bg-gray-600 transition-colors duration-150">
      <div className="h-full flex items-center justify-center">
        <div className="w-1 h-1 bg-gray-500 rounded"></div>
      </div>
    </PanelResizeHandle>
  );

  const componentPanelRef = React.useRef(null);

  useEffect(() => {
    if (componentPanelRef.current) {
      if (components?.length === 0) componentPanelRef.current.resize(0);
      else if (componentPanelRef.current.getSize() === 0)
        componentPanelRef.current.resize(50);
      // componentPanelRef.current.addEventListener("resize", () => {
      //   console.log("resize");
      // });
    }
  }, [components]);

  return (
    <div className="h-screen flex flex-col max-h-screen">
      <Header />

      <div className="flex-1 flex max-h-[calc(100vh-64px)]">
        <PanelGroup direction="horizontal">
          {isSidebarOpen && (
            <>
              <Panel defaultSize={20} minSize={15}>
                <div
                  className={`h-full ${
                    !isSidebarOpen ? "hidden" : ""
                  } md:block`}
                >
                  <Sidebar
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                  />
                </div>
              </Panel>
              {renderResizeHandle()}
            </>
          )}

          <Panel>
            <div className="flex-1 flex flex-col h-full">
              {/* Content Header */}
              <div className="flex h-[62px] items-center justify-between px-4 py-2 border-b border-gray-700">
                <div className="flex items-center gap-4">
                  {!isSidebarOpen && (
                    <button onClick={() => setIsSidebarOpen(true)}>
                      <FaBars size={20} />
                    </button>
                  )}
                  <h1 className="text-sm font-bold font-['Roboto']">
                    {selectedExpert?.name}
                  </h1>
                </div>
                {!isDataSourceOpen && (
                  <button onClick={() => setIsDataSourceOpen(true)}>
                    <FaBars size={20} />
                  </button>
                )}
              </div>

              {/* Content Area with Playground and Chat */}
              <div className="flex-1 overflow-hidden">
                <PanelGroup direction="vertical" className="h-full">
                  <Panel
                    defaultSize={components?.length ? 50 : 0}
                    minSize={components?.length ? 15 : 0}
                    collapsible={true}
                    // maxSize={components?.length ? 0 : 100}
                    className="overflow-y-auto"
                    ref={componentPanelRef}
                  >
                    <div className="overflow-y-auto h-full">
                      {components?.length > 0 && <Playground />}
                    </div>
                  </Panel>

                  {renderResizeHandle()}
                  <Panel defaultSize={50} minSize={15}>
                    <div className="overflow-y-auto flex-1 flex flex-col h-full">
                      <ChatbotComponent
                        isOpen={true}
                        setIsOpen={() => {}}
                        id="chatbot"
                        onSubmit={onSubmit}
                        messages={messages}
                        key={"chat"}
                        selectedFiles={selectedFiles}
                        currentStep={0}
                        setSelectedFiles={setSelectedFiles}
                        downloadableFile={[]}
                        style={{ height: "100%", overflowY: "auto" }}
                        showHeader={false}
                        onRevertMessage={(text) => {
                          ws?.send(JSON.stringify({ revertChatTo: text }));
                        }}
                        faqs={selectedExpert?.faqs}
                        selectedExpert={selectedExpert}
                      />
                    </div>
                  </Panel>
                </PanelGroup>
              </div>
            </div>
          </Panel>

          {isDataSourceOpen && (
            <>
              {renderResizeHandle()}
              <Panel defaultSize={20} minSize={15}>
                <div className="hidden md:block h-full">
                  <DataSource
                    isDataSourceOpen={isDataSourceOpen}
                    setIsDataSourceOpen={setIsDataSourceOpen}
                  />
                </div>
              </Panel>
            </>
          )}
        </PanelGroup>

        {/* Mobile Data Source overlay remains unchanged */}
        {isDataSourceOpen && (
          <div className="md:hidden fixed inset-0 z-50 pointer-events-auto">
            <div
              className="absolute inset-0 bg-black bg-opacity-50"
              onClick={() => setIsDataSourceOpen(false)}
            />
            <div
              className={`absolute top-0 right-0 w-72 h-full transform transition-transform duration-500 ease-[cubic-bezier(0.25, 0.8, 0.25, 1)] bg-[#ebebeb] ${
                isDataSourceOpen ? "translate-x-0" : "translate-x-full"
              }`}
            >
              <DataSource
                isDataSourceOpen={isDataSourceOpen}
                setIsDataSourceOpen={setIsDataSourceOpen}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForCasting;

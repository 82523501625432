import axios from "axios";

const API_BASE_URL =
  process.env.REACT_APP_AGENT_URL || "http://localhost:3011/agent/api";

export const appsApi = {
  // Get files in a directory
  getApplications: async () => {
    const response = await axios.get(`${API_BASE_URL}/apps`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  },

  getDataSources: async (expertId) => {
    const response = await axios.get(
      `${API_BASE_URL}/apps/ds?expertId=${expertId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return response.data;
  },

  saveApplication: async (application) => {
    const response = await axios.post(`${API_BASE_URL}/apps`, application, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return response.data;
  },
};

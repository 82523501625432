import React, { useEffect, useState } from "react";
import { Header } from "./Header";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import ChatbotComponent from "./ChatBotComponent";
import DynamicComponentLoader from "./DynamicComponentLoader";
import { ChatHistoryComponent } from "./ChatHistoryComponent";
import _, { set } from "lodash";
import FileExplorer from "./FileExplorer";
import ResizableContainer from "./ResizableContainer";
import { Responsive, WidthProvider } from "react-grid-layout";
import { FaBars } from 'react-icons/fa'; // Add this import
const ResponsiveGridLayout = WidthProvider(Responsive);

function Explorer() {
  const [isOpen, setIsOpen] = useState(true);
  const [messages, setMessages] = useState([]);
  const [ws, setWs] = useState(null);
  const [downloadableFile, setDownloadableFile] = useState(null);
  const [session, setSession] = useState([]);
  const [currentStep, setCurrentStep] = useState(null);
  const [components, setComponents] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [layouts, setLayouts] = useState([
    {
      i: "component_0",
      x: 0,
      y: 0,
      w: 3,
      h: 2,
    },
    {
      i: "component_1",
      x: 4,
      y: 0,
      w: 3,
      h: 2,
    },
  ]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [leftPanelOpen, setLeftPanelOpen] = useState(false);
  const [rightPanelOpen, setRightPanelOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // create layout for components
    const _layouts = [];
    components.forEach((_, i) => {
      _layouts.push({
        i: `component_${i}`,
        x: (i % 3) * 4,
        y: Math.floor(i / 4) * 2,
        w: 3,
        h: 2,
      });
    });
    setLayouts(_layouts);
  }, [components]);

  // create websocket connection
  useEffect(() => {
    // check if current browser url is not localhost, if not get domain and create url as wss://<domain>/agent/ws/chat?token=<access_token> else
    // create url as ws://localhost:3011/agent/ws/chat?token=<access_token>
    const url = window.location.href.includes("localhost")
      ? "ws://localhost:3011/agent/ws/chat?token="
      : "wss://" + window.location.hostname + "/agent/ws/chat?token=";
    const _ws = new WebSocket(
      url +
        localStorage.getItem("access_token") +
        (session ? "&sessionId=" + session.sessionId : "")
    );
    setWs(_ws);
    let interval = null;
    _ws.onopen = () => {
      console.log("Connected");
      interval = setInterval(() => {
        _ws.send("ping");
      }, 30000);
    };
    _ws.onmessage = (e) => {
      console.log("Message", e.data);
      if (e.data === "pong") {
        return;
      }
      const data = JSON.parse(e.data);
      if (data.sessionHistory) {
        console.log("Session History", data.sessionHistory);
        setMessages(
          (data.sessionHistory.chats || []).map((msg) => JSON.parse(msg))
        );
        setComponents(
          (data.sessionHistory.components || []).map((c) => JSON.parse(c))
        );
      } else
        Object.entries(data).forEach(([key, res]) => {
          console.log("Response", res);

          if (res && res.response) {
            setMessages((prev) => {
              return [
                ...prev,
                {
                  text: res.response,
                  sender: "bot",
                  timestamp: new Date().toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "2-digit",
                    hour12: false,
                  }),
                },
              ];
            });
          } else if (res && res.dynamicComponent) {
            setComponents((p) => [...p, res.dynamicComponent]);
          } else if (res && res.fileId) {
            console.log("Setting Downloadable File", res.fileId);
            setSelectedFiles((p) => [...(p || []), res.fileId]);
            setDownloadableFile(res.fileId);
          } else if (key === "final_result") {
            setCurrentStep(null);
          } else setCurrentStep(key);
        });
    };
    _ws.onclose = () => {
      console.log("Disconnected");
    };
    return () => {
      _ws.close();
      if (interval) clearInterval(interval);
    };
  }, [session]);

  useEffect(() => {
    console.log("Selected Files", selectedFiles);
  }, [selectedFiles]);

  const onSubmit = (e, f) => {
    console.log("Submitted", e);
    setMessages((prev) => {
      return [
        ...prev,
        {
          text: e,
          sender: "user",
          timestamp: new Date().toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "2-digit",
            hour12: false,
          }),
        },
      ];
    });
    ws.send(
      JSON.stringify({
        query: e,
        file: f,
        selectedFiles: selectedFiles.map((f) => f.fileId),
      })
    );
    setSelectedFiles([]);
  };

  const MobileHeader = () => (
    <div style={{
      display: isMobile ? 'flex' : 'none',
      justifyContent: 'space-between',
      padding: '10px',
      alignItems: 'center',
      background: '#f5f5f5',
      borderBottom: '1px solid #ddd'
    }}>
      <FaBars 
        onClick={() => setLeftPanelOpen(!leftPanelOpen)} 
        size={24} 
        style={{ cursor: 'pointer' }}
      />
      <span>File Explorer</span>
      <FaBars 
        onClick={() => setRightPanelOpen(!rightPanelOpen)} 
        size={24} 
        style={{ cursor: 'pointer' }}
      />
    </div>
  );

  if (isMobile) {
    return (
      <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Header />
        <MobileHeader />
        
        {/* File Explorer Section - 30% height */}
        <div style={{ height: '30%', overflow: 'auto', padding: '5px' }}>
          <FileExplorer
            onSelectionChange={(selected) => {
              setSelectedFiles(
                downloadableFile ? [...selected, downloadableFile] : selected
              );
            }}
            downloadableFile={downloadableFile}
          />
        </div>

        {/* Chat Section - Remaining height */}
        <div style={{ height: '70%', position: 'relative' }}>
          <ChatbotComponent
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            id="chatbot"
            onSubmit={onSubmit}
            messages={messages}
            key={session?.sessionId || "chat"}
            selectedFiles={selectedFiles}
            currentStep={currentStep}
            setSelectedFiles={setSelectedFiles}
            downloadableFile={downloadableFile}
            style={{ height: '100%', overflowY: 'auto' }}
            onRevertMessage={(text) => {
              ws.send(JSON.stringify({ revertChatTo: text }));
            }}
          />
        </div>

        {/* Left Sliding Panel - Chat History */}
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '80%',
          height: '100vh',
          background: 'white',
          transform: `translateX(${leftPanelOpen ? '0' : '-100%'})`,
          transition: 'transform 0.3s ease',
          zIndex: 1000,
          boxShadow: '2px 0 5px rgba(0,0,0,0.2)'
        }}>
          <div style={{ padding: '10px', borderBottom: '1px solid #ddd', display: 'flex', justifyContent: 'space-between' }}>
            <span>Chat History</span>
            <button onClick={() => setLeftPanelOpen(false)}>&times;</button>
          </div>
          <ChatHistoryComponent
            onClick={(session) => {
              setSession(session);
              setMessages([]);
              setComponents([]);
              setLeftPanelOpen(false);
            }}
          />
        </div>

        {/* Right Sliding Panel - Playground */}
        <div style={{
          position: 'fixed',
          top: 0,
          right: 0,
          width: '80%',
          height: '100vh',
          background: 'white',
          transform: `translateX(${rightPanelOpen ? '0' : '100%'})`,
          transition: 'transform 0.3s ease',
          zIndex: 1000,
          boxShadow: '-2px 0 5px rgba(0,0,0,0.2)'
        }}>
          <div style={{ padding: '10px', borderBottom: '1px solid #ddd', display: 'flex', justifyContent: 'space-between' }}>
            <span>Playground</span>
            <button onClick={() => setRightPanelOpen(false)}>&times;</button>
          </div>
          <div style={{ padding: '10px', height: 'calc(100% - 50px)', overflowY: 'auto' }}>
            {components.map((component, i) => (
              <DynamicComponentLoader
                key={`component_${i}`}
                url={component?.url}
                {...(component?.props || [])}
                id={`component_${i}`}
                setDelete={(key) => {
                  setComponents((p) => p.filter((c, idx) => `component_${idx}` !== key));
                  // todo remove component from backend
                  ws.send(JSON.stringify({ deleteComponent: i }));
                }}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      <Header />
      <PanelGroup 
        direction={isMobile ? "vertical" : "horizontal"} 
        style={{ flex: 1 }}
      >
        <Panel 
          defaultSize={isMobile ? 25 : 15} 
          minSize={isMobile ? 10 : 15} 
          maxSize={isMobile ? 90 : 50}
        >
          <ChatHistoryComponent
            onClick={(session) => {
              setSession(session);
              setMessages([]);
              setComponents([]);
            }}
          />
        </Panel>
        <PanelResizeHandle />
        <Panel 
          defaultSize={isMobile ? 40 : 70} 
          minSize={isMobile ? 30 : 40}
        >
          <PanelGroup direction="vertical">
            <Panel>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  padding: isMobile ? "5px" : "10px",
                  zIndex: 1000,
                  background: "white",
                }}
              >
                <div
                  className="chat-window-panel"
                  style={{ width: "100%", minWidth: 0 }}
                >
                  <div className="chat-header">
                    <div className="header-content">
                      <div className="bot-avatar">
                        <img
                          width="40"
                          height="40"
                          src="/chatbot.svg"
                          alt="chatbot"
                        />
                      </div>
                      <div className="bot-info">
                        <h3 className="bot-name">File Explorer</h3>
                      </div>
                    </div>
                  </div>
                  <FileExplorer
                    onSelectionChange={(selected) => {
                      setSelectedFiles(
                        downloadableFile
                          ? [...selected, downloadableFile]
                          : selected
                      );
                    }}
                    downloadableFile={downloadableFile}
                  />
                </div>
              </div>
            </Panel>
            <PanelResizeHandle />
            <Panel>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  padding: isMobile ? "5px" : "10px",
                  zIndex: 1000,
                  background: "white",
                }}
              >
                <div
                  className="chat-window-panel"
                  style={{ width: "100%", minWidth: 0 }}
                >
                  <div className="chat-header">
                    <div className="header-content">
                      <div className="bot-avatar">
                        <img
                          width="40"
                          height="40"
                          src="/chatbot.svg"
                          alt="chatbot"
                        />
                      </div>
                      <div className="bot-info">
                        <h3 className="bot-name">Playground</h3>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      padding: isMobile ? "5px" : "10px",
                      overflow: "auto",
                      maxHeight: isMobile ? "calc(100vh - 60px)" : "calc(100vh - 100px)",
                      flex: 1,
                    }}
                  >
                    {components.map((component, i) => {
                      return (
                        <div
                          key={`component_${i}`}
                          style={{
                            overflow: "hidden",
                          }}
                        >
                          <DynamicComponentLoader
                            url={component?.url}
                            {...(component?.props || [])}
                            id={`component_${i}`}
                            setDelete={(key) => {
                              setComponents((p) => {
                                return p.filter(
                                  (c, idx) => `component_${idx}` !== key
                                );
                              });
                              ws.send(JSON.stringify({ deleteComponent: i }));
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Panel>
          </PanelGroup>
        </Panel>
        <PanelResizeHandle />
        <Panel 
          defaultSize={isMobile ? 35 : 30} 
          minSize={isMobile ? 20 : 25} 
          maxSize={isMobile ? 90 : 50}
        >
          <ChatbotComponent
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            id="chatbot"
            onSubmit={onSubmit}
            messages={messages}
            key={session?.sessionId || "chat"}
            selectedFiles={selectedFiles}
            currentStep={currentStep}
            setSelectedFiles={setSelectedFiles}
            downloadableFile={downloadableFile}
            style={{ 
              maxHeight: isMobile ? "100%" : undefined,
              overflowY: "auto"
            }}
            onRevertMessage={(text) => {
              ws.send(JSON.stringify({ revertChatTo: text }));
            }}
          />
        </Panel>
      </PanelGroup>
    </div>
  );
}

export default Explorer;

import React, { useState, useRef, useEffect, memo } from "react";
import {
  X,
  ThumbsUp,
  ThumbsDown,
  Copy,
  CheckCheck,
  Delete,
  DeleteIcon,
} from "lucide-react";
import ReactMarkdown from "react-markdown";
import TypingIndicator from "./TypingIndicator";
import {
  FaDownload,
  FaFile,
  FaFileAlt,
  FaFolder,
  FaImage,
} from "react-icons/fa";
import { fileExplorerApi } from "../../services/fileExplorerApi";
import { useAuth } from "../../context/AuthContext";
import remarkGfm from "remark-gfm";

const ChatbotComponent = ({
  messages,
  onSubmit,
  currentStep,
  selectedFiles,
  setSelectedFiles,
  onRevertMessage,
  showHeader = true,
  faqs,
  selectedExpert,
}) => {
  const getFileIcon = (file) => {
    if (file.fileType === "folder")
      return <FaFolder className="text-yellow-500" />;
    if (file.fileName.match(/\.(jpg|jpeg|png|gif)$/i))
      return <FaImage className="text-blue-500" />;
    if (file.fileName.match(/\.(txt|md|doc|docx)$/i))
      return <FaFileAlt className="text-gray-500" />;
    return <FaFile className="text-gray-400" />;
  };
  const messagesEndRef = useRef(null);
  const [inputText, setInputText] = useState("");
  // base64 encoded file with data URI to get the file type
  const [inputFile, setInputFile] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedMode, setSelectedMode] = useState(
    selectedExpert?.model || "gpt-4o"
  );

  const handleDelete = (message) => {
    console.log(message);
    if (onRevertMessage) {
      onRevertMessage(message.text);
    }
  };

  useEffect(() => {
    setSelectedMode(selectedExpert?.model || "gpt-4o");
  }, [selectedExpert]);

  const handleDownload = async (file) => {
    try {
      const blob = await fileExplorerApi.downloadFile("userId", file.fileId);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      console.error(err);
    }
  };
  const [quickActions, setQuickActions] = useState(
    faqs || ["Hi, How is the weather today?", "Show it as a widget", "🤖 FAQs"]
  );

  useEffect(() => {
    setQuickActions(
      faqs || [
        "Hi, How is the weather today?",
        "Show it as a widget",
        "🤖 FAQs",
      ]
    );
  }, [faqs]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]); // Scroll whenever messages change

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(inputText, inputFile, selectedMode); // Add selectedMode to onSubmit
    if (inputText.trim()) {
      setInputText("");
      setInputFile(null);
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    // Optional: Add toast notification here
    // toast.success('Copied to clipboard!');
  };

  const handleLike = (messageIndex) => {};

  const handleDislike = (messageIndex) => {};

  const { user } = useAuth();

  return (
    <div className="relative w-full flex-1 p-4 bg-white flex flex-col overflow-hidden">
      <div className="flex flex-1 flex-col rounded-lg shadow-lg overflow-hidden">
        {showHeader && (
          <div className="flex items-center border-b pb-4 mb-4">
            <img
              width={40}
              height={40}
              src="/chatbot.svg"
              alt="chatbot"
              className="mr-3"
            />
            <div>
              <h3 className="font-semibold">Kaman AI</h3>
              <div className="flex items-center text-sm text-gray-600">
                <div className="w-2 h-2 rounded-full bg-green-500 mr-2"></div>
                <span>Online</span>
              </div>
            </div>
          </div>
        )}

        <div className="flex-1 p-2 pl-4 flex flex-col overflow-y-auto max-w-full">
          {messages.map((message, index) => (
            <div key={index} className="flex items-start mb-4 space-x-3">
              <div className="flex-shrink-0">
                {message.sender === "bot" ? (
                  <div className="w-10 h-10 flex text-xl font-bold items-center justify-center text-white bg-gray-600 rounded-md">
                    B
                  </div>
                ) : (
                  <div className="w-10 h-10 flex items-center text-xl font-bold justify-center text-gray-700 bg-gray-300 rounded-md">
                    {`${user?.username}`.charAt(0).toUpperCase() || "U"}
                  </div>
                )}
              </div>
              <div className="flex-1 flex flex-col max-w-full">
                <div className="inline-block rounded-lg px-0 py-0 " title={
                  message.text?.includes("</think>") ? message.text?.split("</think>")[0] : ''
                }>
                  <ReactMarkdown remarkPlugins={[remarkGfm]} className="prose">
                    {message.text?.includes("</think>") ? message.text?.split("</think>")[1] : message.text?.length > 10000
                      ? `${message.text?.includes("</think>") ? message.text?.split("</think>")[1] : message.text?.substring(0, 1000)}...`
                      : message.text?.includes("</think>") ? message.text?.split("</think>")[1] : message.text}
                  </ReactMarkdown>
                  {message.file && (
                    <img
                      src={message.file}
                      alt="Uploaded file"
                      className="mt-2 max-w-xs rounded"
                    />
                  )}
                </div>
                <div className="flex mt-1 space-x-2 text-sm text-gray-500 flex-1 flex-row justify-start align-top items-start max-w-full">
                  <span className="text-xs justify-start align-top">{message.timestamp}</span>
                  {message.sender === "user" && (
                    <CheckCheck
                      className={`h-4 w-4 ${
                        !message.read ? "text-green-500" : "text-gray-400"
                      }`}
                    />
                  )}
                  <div className="flex space-x-2 flex-1 max-w-full flex-wrap gap-2">
                    {message.sender === "bot" ? (
                      <>
                        <button
                          onClick={() => handleCopy(message.text)}
                          className="hover:text-gray-700"
                        >
                          <Copy className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => handleLike(index)}
                          className="hover:text-gray-700"
                        >
                          <ThumbsUp className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => handleDislike(index)}
                          className="hover:text-gray-700"
                        >
                          <ThumbsDown className="h-4 w-4" />
                        </button>
                        {message.suggestions &&
                          (message.suggestions || []).map(
                            (suggestion, index) => (
                              <button
                                key={index}
                                onClick={() =>
                                  onSubmit(suggestion, [], selectedMode)
                                }
                                title={suggestion}
                                className="hover:bg-gray-200 hover:text-gray-700 text-xs text-ellipsis rounded-full px-2 py-1 bg-gray-700 text-white overflow-hidden max-w-60 text-nowrap"
                              >
                                {suggestion}
                              </button>
                            )
                          )}
                      </>
                    ) : (
                      <button
                        onClick={() => handleDelete(message)}
                        className="hover:text-gray-700"
                      >
                        <DeleteIcon className="h-4 w-4" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* <TypingIndicator currentStep={currentStep} /> */}
          <div ref={messagesEndRef} />
        </div>

        <div className="mt-4 p-2 border-t pt-4">
          <div className="flex flex-wrap gap-2 mb-4">
            {quickActions.map((action, index) => (
              <button
                key={index}
                className="px-4 py-2 text-sm bg-gray-100 rounded-full hover:bg-gray-200"
                onClick={() => onSubmit(action, [], selectedMode)}
              >
                {action}
              </button>
            ))}
          </div>

          {inputFile && (
            <img
              src={inputFile}
              alt="Uploaded file"
              className="max-h-32 rounded mb-2"
            />
          )}

          <div className="mb-4">
            {(selectedFiles || []).map((file, index) => (
              <div
                key={index}
                className="flex items-center mb-1 p-2 bg-gray-50 rounded"
              >
                {getFileIcon(file)}
                <span className="ml-2 flex-1">{file.fileName}</span>
                <button
                  type="button"
                  className="p-1 hover:text-gray-700"
                  onClick={() => {
                    selectedFiles.splice(index, 1);
                    setSelectedFiles([...selectedFiles]);
                  }}
                >
                  <X className="h-4 w-4" />
                </button>
                <button
                  type="button"
                  className="p-1 hover:text-gray-700"
                  onClick={() => handleDownload(file)}
                >
                  <FaDownload className="h-4 w-4" />
                </button>
              </div>
            ))}
          </div>

          <form onSubmit={handleSubmit} className="flex items-end space-x-2">
            {/* <select
              value={selectedMode}
              onChange={(e) => setSelectedMode(e.target.value)}
              className="px-3 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-gray-800"
            >
              <option value="gpt-4o">GPT-4o</option>
              <option value="qwen">Qwen</option>
            </select> */}
            <textarea
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onInput={(e) => {
                e.target.style.height = "auto";
                e.target.style.height = `${e.target.scrollHeight}px`;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit(e);
                }
              }}
              placeholder="Type your message here..."
              className="flex-1 resize-none rounded-lg border p-3 focus:outline-none focus:ring-2 focus:ring-gray-800"
              rows={1}
              style={{ maxHeight: "150px" }}
            />
            <button
              type="button"
              onClick={() => fileInputRef.current.click()}
              className="p-3 text-gray-500 hover:text-gray-700"
            >
              <svg viewBox="0 0 280.067 280.067" className="h-6 w-6">
                <g xmlns="http://www.w3.org/2000/svg">
                  <path d="M149.823,257.142c-31.398,30.698-81.882,30.576-113.105-0.429c-31.214-30.987-31.337-81.129-0.42-112.308l-0.026-0.018L149.841,31.615l14.203-14.098c23.522-23.356,61.65-23.356,85.172,0s23.522,61.221,0,84.586l-125.19,123.02l-0.044-0.035c-15.428,14.771-40.018,14.666-55.262-0.394c-15.244-15.069-15.34-39.361-0.394-54.588l-0.044-0.053l13.94-13.756l69.701-68.843l13.931,13.774l-83.632,82.599c-7.701,7.596-7.701,19.926,0,27.53s20.188,7.604,27.88,0L235.02,87.987l-0.035-0.026l0.473-0.403c15.682-15.568,15.682-40.823,0-56.39s-41.094-15.568-56.776,0l-0.42,0.473l-0.026-0.018l-14.194,14.089L50.466,158.485c-23.522,23.356-23.522,61.221,0,84.577s61.659,23.356,85.163,0l99.375-98.675l14.194-14.089l14.194,14.089l-14.194,14.098l-99.357,98.675C149.841,257.159,149.823,257.142,149.823,257.142z" />
                </g>
              </svg>
            </button>
            <button
              type="submit"
              className="p-3 text-gray-700 hover:text-gray-900"
            >
              <svg viewBox="0 0 24 24" className="h-6 w-6 fill-current">
                <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
              </svg>
            </button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    setInputFile(reader.result);
                  };
                  reader.readAsDataURL(file);
                }
              }}
              className="hidden"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default memo(ChatbotComponent);

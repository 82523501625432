import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import ApplicationForm from "./ApplicationForm";
import Portal from "../Common/Portal";

function LeftBar({
  applications,
  setSelectedApplication,
  selectedApplication,
  saveApplication,
}) {
  const { user } = useAuth();
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    itemIndex: null
  });
  const [showForm, setShowForm] = useState(false);
  const [editingApplication, setEditingApplication] = useState(null);

  // Handler for adding a new item
  const handleAddItem = () => {
    setEditingApplication(null);
    setShowForm(true);
  };

  // Handler for selecting an item
  const handleSelectItem = (index) => {
    setSelectedApplication(applications[index]);
  };

  const handleContextMenu = (e, index) => {
    e.preventDefault();
    setContextMenu({
      visible: true,
      x: e.clientX,
      y: e.clientY,
      itemIndex: index
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu({ visible: false, x: 0, y: 0, itemIndex: null });
  };

  const handleEditApplication = (index) => {
    setEditingApplication(applications[index]);
    console.log("Editing application:", applications[index]);
    setShowForm(true);
    handleCloseContextMenu();
  };

  const handleSubmitForm = async (formData) => {
    // Handle form submission here - you'll need to implement the API call
    console.log('Form submitted:', formData);
    setShowForm(false);
    setEditingApplication(null);
    saveApplication(formData);
  };

  if (applications?.length < 2 && !(user?.roles || []).includes("admin")) {
    return <></>;
  }
  return (
    <>
      <div className="min-w-[60px] w-[60px] flex-1 max-w-20 bg-[#1B1A21] border-r border-[#cccccc66] flex flex-col items-center pt-5 overflow-hidden">
        {/* Render items dynamically */}
        <div className="flex flex-col justify-start items-center gap-3.5">
          {applications?.map((item, index) => (
            <div
              key={index}
              onClick={() => handleSelectItem(index)}
              onContextMenu={(e) => handleContextMenu(e, index)}
              className={`w-[44px] h-[44px] relative flex justify-center items-center cursor-pointer ${
                item === selectedApplication
                  ? "bg-white/50 border-4 border-white"
                  : "bg-white/50 border border-[#9f9f9f]"
              } rounded-[10px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]`}
            >
              <span className="text-white text-[30px] font-normal font-['Roboto']">
                {item?.name?.substring(0, 1)?.toUpperCase()}
              </span>
            </div>
          ))}
          {/* Add new item button */}
          {(user?.roles || []).includes("admin") && (
            <div
              onClick={handleAddItem}
              className="w-[44px] h-[44px] relative flex justify-center items-center cursor-pointer bg-white/50 border border-[#9f9f9f] rounded-[10px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]"
            >
              <span className="text-white text-[30px] font-normal font-['Roboto']">
                +
              </span>
            </div>
          )}
        </div>

        {contextMenu.visible && (
          <Portal>
            <div
              className="fixed inset-0 w-screen h-screen"
              onClick={handleCloseContextMenu}
            />
            <div
              className="fixed bg-white rounded-lg shadow-lg py-2 min-w-[150px]"
              style={{ left: contextMenu.x, top: contextMenu.y }}
            >
              <button
                className="w-full px-4 py-2 text-left hover:bg-gray-100"
                onClick={() => handleEditApplication(contextMenu.itemIndex)}
              >
                Edit Application
              </button>
              {/* Add more context menu items here */}
            </div>
          </Portal>
        )}
      </div>

      {showForm && (
        <ApplicationForm
          application={editingApplication}
          onSubmit={handleSubmitForm}
          onClose={() => {
            setShowForm(false);
            setEditingApplication(null);
          }}
        />
      )}
    </>
  );
}

export default LeftBar;

import axios from "axios";

const API_BASE_URL =
  process.env.REACT_APP_AGENT_URL || "http://localhost:3011/agent/api";

export const componentApi = {
  // Get files in a directory
  getBase64Code: async (code) => {
    const response = await axios.post(
      `${API_BASE_URL}/file`,
      { code },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return response.data;
  },
};

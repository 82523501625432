import React, { useState } from "react";
import Portal from "../Common/Portal";
import Editor from "@monaco-editor/react";
import JsonView from "@uiw/react-json-view";
import { FaPen, FaTimes, FaTrash } from "react-icons/fa";
import DynamicComponent from "../DynamicComponentLoader";
import { componentApi } from "../../services/componentApi";

function ComponentEditor({ component, onChange, onRemove }) {
  const [activeTab, setActiveTab] = useState("code");
  const [preview, setPreview] = useState(null);

  const handleCodeChange = (value) => {
    onChange({ ...component, code: value });
  };

  const handlePropsChange = (value) => {
    onChange({ ...component, props: value });
  };

  const handlePreview = () => {
    try {
      // Execute the component code in a safe way
      const code = component.code;
      const props = component.props;
      console.log(code);
      console.log(props);
      componentApi.getBase64Code(code).then((data) => {
        console.log(data);
        // This is just a basic preview - you might want to enhance this
        // set url of the component object as the base64 value
        onChange({ ...component, url: data.base64Code });
        setPreview(
          <div className="p-4 border rounded">
            {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
            <DynamicComponent url={data.base64Code} {...props} />
          </div>
        );
      });
    } catch (error) {
      setPreview(<div className="text-red-500">Error: {error.message}</div>);
    }
  };

  return (
    <div className="border rounded p-2">
      <div className="flex justify-between mb-2">
        <div className="flex gap-2">
          <button
            type="button"
            onClick={() => setActiveTab("code")}
            className={`px-3 py-1 rounded ${
              activeTab === "code" ? "bg-blue-500 text-white" : "bg-gray-100"
            }`}
          >
            Code
          </button>
          <button
            type="button"
            onClick={() => setActiveTab("props")}
            className={`px-3 py-1 rounded ${
              activeTab === "props" ? "bg-blue-500 text-white" : "bg-gray-100"
            }`}
          >
            Props
          </button>
          <button
            type="button"
            onClick={() => setActiveTab("preview")}
            className={`px-3 py-1 rounded ${
              activeTab === "preview" ? "bg-blue-500 text-white" : "bg-gray-100"
            }`}
            onMouseEnter={handlePreview}
          >
            Preview
          </button>
        </div>
        <button
          type="button"
          onClick={onRemove}
          className="text-red-500 hover:text-red-700"
        >
          <FaTrash />
        </button>
      </div>

      <div className="h-[300px]">
        {activeTab === "code" && (
          <Editor
            height="100%"
            fontSize={10}
            defaultLanguage="javascript"
            value={component.code}
            onChange={handleCodeChange}
            theme="vs-dark"
            options={{
              minimap: { enabled: false },
              wordWrap: "on",
              fontSize: 12,
            }}
          />
        )}
        {activeTab === "props" && (
          <div className="h-full overflow-auto">
            {/* <JsonView
              value={component.props || {}}
              onChange={handlePropsChange}
              displayDataTypes={false}
              style={{ height: "100%", overflow: "auto" }}
            /> */}
            <Editor
              height="100%"
              fontSize={10}
              defaultLanguage="json"
              value={JSON.stringify(component.props, null, 2)}
              onChange={(v) => {
                try {
                  handlePropsChange(JSON.parse(v));
                } catch (e) {
                  console.log(e);
                }
              }}
              theme="vs-dark"
              options={{
                minimap: { enabled: false },
                wordWrap: "on",
                fontSize: 12,
              }}
            />
          </div>
        )}
        {activeTab === "preview" && (
          <div className="h-full overflow-auto bg-gray-50 p-2">{preview}</div>
        )}
      </div>
    </div>
  );
}

function ApplicationForm({ application, onSubmit, onClose }) {
  const [formData, setFormData] = useState({
    name: application?.name || "",
    description: application?.description || "",
    icon: application?.icon || "",
    options: application?.options || [
      {
        systemPrompt: "",
        name: "",
        components: [],
        faqs: [],
        tools: [],
        datasources: [],
      },
    ],
    id: application?.id || null,
  });
  const [activeOptionTab, setActiveOptionTab] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const addOption = () => {
    setFormData({
      ...formData,
      options: [
        ...formData.options,
        {
          systemPrompt: "",
          name: "",
          components: [],
          faqs: [],
          tools: [],
          datasources: [],
        },
      ],
    });
  };

  const handleArrayFieldChange = (optionIndex, field, value) => {
    const newOptions = [...formData.options];
    newOptions[optionIndex][field] = value
      .split("\n")
      .filter((item) => item.trim());
    setFormData({ ...formData, options: newOptions });
  };

  const handleComponentChange = (optionIndex, componentIndex, newComponent) => {
    const newOptions = [...formData.options];
    newOptions[optionIndex].components[componentIndex] = newComponent;
    setFormData({ ...formData, options: newOptions });
  };

  const removeOption = (indexToRemove) => {
    const newOptions = formData.options.filter(
      (_, index) => index !== indexToRemove
    );
    setFormData({ ...formData, options: newOptions });
    if (activeOptionTab >= indexToRemove && activeOptionTab > 0) {
      setActiveOptionTab(activeOptionTab - 1);
    }
  };

  const removeComponent = (optionIndex, componentIndex) => {
    const newOptions = [...formData.options];
    newOptions[optionIndex].components = newOptions[
      optionIndex
    ].components.filter((_, index) => index !== componentIndex);
    setFormData({ ...formData, options: newOptions });
  };

  const removeDatasource = (optionIndex, datasourceIndex) => {
    const newOptions = [...formData.options];
    newOptions[optionIndex].datasources = newOptions[
      optionIndex
    ].datasources.filter((_, index) => index !== datasourceIndex);
    setFormData({ ...formData, options: newOptions });
  };

  return (
    <Portal>
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 w-full max-w-4xl overflow-y-auto">
          <h2 className="text-xl font-bold mb-4 flex justify-between max-h-16">
            {application ? "Edit Application" : "Create Application"}
            {/** close icon to close popup */}
            <FaTimes
              className="cursor-pointer hover:text-gray-700"
              onClick={onClose}
            />
          </h2>
          <form
            onSubmit={handleSubmit}
            className="space-y-4 max-h-[calc(90vh-4rem)] overflow-y-auto"
          >
            {/* Basic fields */}
            <div className="space-y-4">
              <div>
                <label className="block mb-1">Name</label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  className="w-full border rounded p-2"
                  required
                />
              </div>
              <div>
                <label className="block mb-1">Description</label>
                <textarea
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                  className="w-full border rounded p-2"
                  rows="3"
                />
              </div>
              <div>
                <label className="block mb-1">Icon</label>
                <input
                  type="text"
                  value={formData.icon}
                  onChange={(e) =>
                    setFormData({ ...formData, icon: e.target.value })
                  }
                  className="w-full border rounded p-2"
                />
              </div>
            </div>

            {/* Options as tabs */}
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <h3 className="font-bold">Options</h3>
                <button
                  type="button"
                  onClick={addOption}
                  className="text-sm text-blue-500 hover:underline"
                >
                  + Add Option
                </button>
              </div>

              <div className="border rounded">
                {/* Option Tabs with Remove Buttons */}
                <div className="flex border-b">
                  {formData.options.map((option, index) => (
                    <div key={index} className="flex items-center">
                      <button
                        type="button"
                        onClick={() => setActiveOptionTab(index)}
                        className={`px-4 py-2 ${
                          activeOptionTab === index
                            ? "border-b-2 border-blue-500 text-blue-500"
                            : "text-gray-500 hover:text-gray-700"
                        }`}
                      >
                        {option.name || `Option ${index + 1}`}
                      </button>
                      {formData.options.length > 1 && (
                        <button
                          type="button"
                          onClick={() => removeOption(index)}
                          className="px-2 text-red-500 hover:text-red-700"
                        >
                          <FaTrash size={12} />
                        </button>
                      )}
                    </div>
                  ))}
                </div>

                {/* Active Option Content */}
                <div className="p-4 space-y-4">
                  <div className="space-y-2">
                    <label className="block text-xs font-medium">
                      Option Name
                    </label>
                    <input
                      type="text"
                      placeholder="Option Name"
                      value={formData.options[activeOptionTab].name}
                      onChange={(e) => {
                        const newOptions = [...formData.options];
                        newOptions[activeOptionTab].name = e.target.value;
                        setFormData({ ...formData, options: newOptions });
                      }}
                      className="w-full border rounded p-2"
                    />
                    <label className="block text-xs font-medium">
                      System Prompt
                    </label>
                    <textarea
                      placeholder="System Prompt"
                      value={formData.options[activeOptionTab].systemPrompt}
                      onChange={(e) => {
                        const newOptions = [...formData.options];
                        newOptions[activeOptionTab].systemPrompt =
                          e.target.value;
                        setFormData({ ...formData, options: newOptions });
                      }}
                      className="w-full border rounded p-2"
                      rows="2"
                    />
                    <label className="block text-xs font-medium">Model</label>
                    <select
                      value={formData.options[activeOptionTab].model || "gpt-4o"}
                      onChange={(e) => {
                        const newOptions = [...formData.options];
                        newOptions[activeOptionTab].model = e.target.value;
                        setFormData({ ...formData, options: newOptions });
                      }}
                      className="px-3 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-gray-800"
                    >
                      <option value="gpt-4o">GPT-4o</option>
                      <option value="qwen">Qwen</option>
                    </select>

                    {/* Components with Remove Button */}
                    <div className="space-y-2">
                      <label className="block text-sm font-medium">
                        Components
                      </label>
                      {formData.options[activeOptionTab].components?.map(
                        (comp, componentIndex) => (
                          <ComponentEditor
                            key={componentIndex}
                            component={comp}
                            onChange={(newComponent) =>
                              handleComponentChange(
                                activeOptionTab,
                                componentIndex,
                                newComponent
                              )
                            }
                            onRemove={() =>
                              removeComponent(activeOptionTab, componentIndex)
                            }
                          />
                        )
                      )}
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          const newOptions = [...formData.options];
                          if (!newOptions[activeOptionTab].components) {
                            newOptions[activeOptionTab].components = [];
                          }
                          newOptions[activeOptionTab].components.push({
                            component: "",
                            props: {},
                          });
                          setFormData({ ...formData, options: newOptions });
                        }}
                        className="text-sm text-blue-500 hover:underline"
                      >
                        + Add Component
                      </button>
                    </div>

                    {/* FAQs */}
                    <div className="space-y-1">
                      <label className="block text-sm font-medium">FAQs</label>
                      <textarea
                        placeholder="Enter FAQs (one per line)"
                        value={formData.options[activeOptionTab].faqs?.join(
                          "\n"
                        )}
                        onChange={(e) =>
                          handleArrayFieldChange(
                            activeOptionTab,
                            "faqs",
                            e.target.value
                          )
                        }
                        className="w-full border rounded p-2"
                        rows="3"
                      />
                    </div>

                    {/* Tools */}
                    <div className="space-y-1">
                      <label className="block text-sm font-medium">Tools</label>
                      <textarea
                        placeholder="Enter tools (one per line)"
                        value={formData.options[activeOptionTab].tools?.join(
                          "\n"
                        )}
                        onChange={(e) =>
                          handleArrayFieldChange(
                            activeOptionTab,
                            "tools",
                            e.target.value
                          )
                        }
                        className="w-full border rounded p-2"
                        rows="2"
                      />
                    </div>

                    {/* Datasources with Remove Button */}
                    <div className="space-y-1">
                      <label className="block text-sm font-medium">
                        Datasources
                      </label>
                      {formData.options[activeOptionTab].datasources?.map(
                        (ds, dsIndex) => (
                          <div
                            key={dsIndex}
                            className="space-y-2 border-l-2 pl-2 mb-2 flex gap-2"
                          >
                            <div className="flex-1">
                              <input
                                type="text"
                                placeholder="Name"
                                value={ds.name}
                                onChange={(e) => {
                                  const newOptions = [...formData.options];
                                  newOptions[activeOptionTab].datasources[
                                    dsIndex
                                  ].name = e.target.value;
                                  setFormData({
                                    ...formData,
                                    options: newOptions,
                                  });
                                }}
                                className="w-full border rounded p-1"
                              />
                              <select
                                value={ds.type}
                                onChange={(e) => {
                                  const newOptions = [...formData.options];
                                  newOptions[activeOptionTab].datasources[
                                    dsIndex
                                  ].type = e.target.value;
                                  setFormData({
                                    ...formData,
                                    options: newOptions,
                                  });
                                }}
                                className="w-full border rounded p-1"
                              >
                                <option value="file">File</option>
                                <option value="function">Function</option>
                                <option value="text">Text</option>
                              </select>
                              <input
                                type="text"
                                placeholder={
                                  ds.type === "file"
                                    ? "Path"
                                    : ds.type === "function"
                                    ? "Function Name"
                                    : "Content"
                                }
                                value={
                                  ds.path || ds.functionName || ds.content || ""
                                }
                                onChange={(e) => {
                                  const newOptions = [...formData.options];
                                  const value = e.target.value;
                                  if (ds.type === "file")
                                    newOptions[activeOptionTab].datasources[
                                      dsIndex
                                    ].path = value;
                                  else if (ds.type === "function")
                                    newOptions[activeOptionTab].datasources[
                                      dsIndex
                                    ].functionName = value;
                                  else
                                    newOptions[activeOptionTab].datasources[
                                      dsIndex
                                    ].content = value;
                                  setFormData({
                                    ...formData,
                                    options: newOptions,
                                  });
                                }}
                                className="w-full border rounded p-1"
                              />
                            </div>
                            <button
                              type="button"
                              onClick={() =>
                                removeDatasource(activeOptionTab, dsIndex)
                              }
                              className="self-start p-1 text-red-500 hover:text-red-700"
                            >
                              <FaTrash />
                            </button>
                          </div>
                        )
                      )}
                      <button
                        type="button"
                        onClick={() => {
                          const newOptions = [...formData.options];
                          newOptions[activeOptionTab].datasources = [
                            ...(formData.options[activeOptionTab].datasources ||
                              []),
                            {
                              name: "",
                              type: "file",
                              path: "",
                            },
                          ];
                          setFormData({ ...formData, options: newOptions });
                        }}
                        className="text-sm text-blue-500 hover:underline"
                      >
                        + Add Datasource
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-6">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 border rounded hover:bg-gray-100"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </Portal>
  );
}

export default ApplicationForm;

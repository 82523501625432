// DynamicComponentLoader.jsx
import { Trash2 } from "lucide-react";
import React, { useState, useEffect } from "react";
import { FaDownload } from "react-icons/fa";
import * as Recharts from "recharts";
window.React = React; // Add this in your DynamicComponentLoader
window.Recharts = Recharts; // Add this in your DynamicComponentLoader

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div>Something went wrong.</div>;
    }

    return this.props.children;
  }
}

const DynamicComponentLoader = ({ url, setDelete, id, ...props }) => {
  const [Component, setComponent] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadComponent = async () => {
      try {
        // Make React available globally for the remote component
        window.React = React;
        window.Recharts = Recharts;

        // Create a unique ID for this component
        const componentId = `remote-component-${Math.random()
          .toString(36)
          .substr(2, 9)}`;

        // Create a script element
        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        script.id = componentId;

        // Create a promise to wait for the script to load and register the component
        const loadPromise = new Promise((resolve, reject) => {
          // Define a global callback that will be called by the remote component
          window[`${componentId}Callback`] = (exportedComponent) => {
            resolve(exportedComponent);
          };

          script.onerror = () =>
            reject(new Error("Failed to load component script"));
        });

        // Add the script to the document
        document.head.appendChild(script);

        // Wait for the component to load and register
        const loadedComponent = await loadPromise;
        setComponent(() => loadedComponent);

        // Cleanup
        delete window[`${componentId}Callback`];
      } catch (err) {
        setError(err);
        console.error("Error loading component:", err);
      } finally {
        setLoading(false);
      }
    };

    loadComponent();

    // Cleanup function
    return () => {
      const scripts = document.getElementsByTagName("script");
      for (let script of scripts) {
        if (script.src === url) {
          document.head.removeChild(script);
        }
      }
    };
  }, [url]);

  if (loading) {
    return <div>Loading component...</div>;
  }

  if (error) {
    return <div>Error loading component: {error.message}</div>;
  }
  const widgetRef = React.createRef();

  return (
    <div className="flex m-2 bg-white border-[#DDD] rounded-md shadow-md p-3 flex-col flex-1">
      <div
        className="flex items-center justify-between"
        ref={widgetRef}
      >
        <div
          style={{
            fontSize: 14,
            fontWeight: "bold",
            background: "#F9F9F9",
          }}
        >
          &nbsp;
        </div>
        <Trash2 size={15} onClick={() => setDelete(id)} />
      </div>
      <ErrorBoundary>
        {Component ? <Component {...props} /> : null}
      </ErrorBoundary>
    </div>
  );
};

export default DynamicComponentLoader;

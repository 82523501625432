import React from "react";
import { useExpert } from "./ExpertProvider";
import DynamicComponentLoader from "../DynamicComponentLoader";

export const Playground = () => {
  const { components } = useExpert();

  return (
    <div className="flex flex-row flex-wrap w-full flex-1 overflow-y-auto">
      {(components || [])?.filter(c => c.url || c.component).map((component) => (
        <DynamicComponentLoader
          url={component.url || component.component}
          setDelete={() => {}}
          {...component.props}
        />
      ))}
      {components?.length === 0 && (
        <div className="flex items-center justify-center h-full">
          <p className="text-gray-500">Widgets will show up here. Talk to the chatbot to create widgets</p>
        </div>
      )}
    </div>
  );
};
